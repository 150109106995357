import { gql } from "urql";

export const keyResources = gql(`
query KeyResources($locale: I18NLocaleCode) {
  blogPosts(
    pagination: { pageSize: 3, page: 1 }
    sort: ["publishDate:desc", "createdAt:desc"]
    locale: $locale
      ) {
      data {
        id
        attributes {
          title
          post
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`);
