import { gql } from "urql";

export const blogData = gql(`
  query BlogData($locale: I18NLocaleCode, $slug: String) {
    blogPosts(filters: { slug: { eq: $slug } }, pagination: { pageSize: 1 }, locale: $locale) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
                caption
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          tags {
            data {
              attributes {
                name
                uid
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`);
