import { gql } from "urql";

export const resourcesHomepage = gql(`
query GetResourcesHomepage($locale: I18NLocaleCode!, $tag: String) {
    getResourcesHomepage(locale: $locale, tag: $tag) {
      featuredResources {
        id
        title
        excerpt
        featuredImage
        featuredImageFormats {
          thumbnail {
            url
          }
          xsmall {
            url
          }
          small {
            url
          }
          large {
            url
          }
          medium {
            url
          }
          xlarge {
            url
          }
          xxlarge {
            url
          }
        }
        altText
        tag
        slug
        contentType
        minutes
      }

      blogs {
        id
        title
        excerpt
        featuredImage
        featuredImageFormats {
          thumbnail {
            url
          }
          xsmall {
            url
          }
          small {
            url
          }
          large {
            url
          }
          medium {
            url
          }
          xlarge {
            url
          }
          xxlarge {
            url
          }
        }
        altText
        tag
        slug
        contentType
        minutes
      }

      caseStudies {
        id
        title
        excerpt
        featuredImage
        featuredImageFormats {
          thumbnail {
            url
          }
          xsmall {
            url
          }
          small {
            url
          }
          large {
            url
          }
          medium {
            url
          }
          xlarge {
            url
          }
          xxlarge {
            url
          }
        }
        altText
        tag
        slug
        contentType
        minutes
      }

      newsEvents {
        id
        title
        excerpt
        featuredImage
        featuredImageFormats {
          thumbnail {
            url
          }
          xsmall {
            url
          }
          small {
            url
          }
          large {
            url
          }
          medium {
            url
          }
          xlarge {
            url
          }
          xxlarge {
            url
          }
        }
        altText
        tag
        slug
        contentType
        minutes
      }
    }
  }
`);
