import { gql } from "urql";

const GET_POST_QUERY = gql(`
  query posts(
    $pagination: PaginationArg
    $blogFilters: BlogPostFiltersInput
    $caseStudyFilters: CaseStudyFiltersInput
    $eventFilters: EventFiltersInput
  ) {
    blogPosts(pagination: $pagination, filters: $blogFilters) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
    caseStudies(pagination: $pagination, filters: $caseStudyFilters) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
    events(pagination: $pagination, filters: $eventFilters) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`);

export default GET_POST_QUERY;
