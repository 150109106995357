import Bugsnag from "@bugsnag/js";
import { Department, Job, JobLinks } from "./types";
import { handleTeamTailorErrors, teamTailorErrorResponse } from "./helpers";

class TeamTailorService {
  public apiUrl: string;
  public headers: {
    Authorization: string;
    "X-Api-Version": string;
    "Content-Type": string;
  };

  constructor() {
    this.apiUrl = "https://api.teamtailor.com/v1";
    this.headers = {
      Authorization: `Bearer ${process.env.TEAM_TAILOR}`,
      "X-Api-Version": "20210218",
      "Content-Type": "application/vnd.api+json",
    };
  }

  getSlugFromJobUrl(links: JobLinks): string {
    const url = links["careersite-job-url"];
    const parts = url.split("/");

    return parts[parts.length - 1];
  }

  // TODO - pagination
  async getJobs(limit = 30): Promise<Job[]> {
    try {
      const url = `${this.apiUrl}/jobs?page%5Bsize%5D=${limit}`;
      const response = await fetch(url, {
        headers: this.headers,
      });

      const result = handleTeamTailorErrors(response, "getJobs");

      if (result === null) {
        return null;
      }

      const jsonData = await result.json();
      return jsonData?.data || [];
    } catch (e) {
      Bugsnag.notify(e);
      return [];
    }
  }

  async getJob(id: string): Promise<Job> {
    try {
      const url = `${this.apiUrl}/jobs/${id}`;
      const response = await fetch(url, {
        headers: this.headers,
      });

      const result = handleTeamTailorErrors(response, "getJob");

      if (result === null) {
        return null;
      }

      const jsonData = await result.json();
      return jsonData?.data || null;
    } catch (e) {
      Bugsnag.notify(e);
      return null;
    }
  }

  async getDepartment(id: string): Promise<Department> {
    try {
      const url = `${this.apiUrl}/jobs/${id}/department`;
      const response = await fetch(url, {
        headers: this.headers,
      });

      const result = handleTeamTailorErrors(response, "getDepartment");

      if (result === null) {
        return null;
      }

      const jsonData = await result.json();
      return jsonData?.data || null;
    } catch (e) {
      Bugsnag.notify(e);
      return null;
    }
  }

  async getLocation(id: string): Promise<Location> {
    try {
      const url = `${this.apiUrl}/jobs/${id}/location`;
      const response = await fetch(url, {
        headers: this.headers,
      });

      const result = handleTeamTailorErrors(response, "getLocation");

      if (result === null) {
        return null;
      }

      const jsonData = await result.json();
      return jsonData?.data || null;
    } catch (e) {
      Bugsnag.notify(e);
      return null;
    }
  }
}

export const teamTailorService = new TeamTailorService();
