/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** A string used to identify an i18n locale */
  I18NLocaleCode: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  SupportHubResourceResourceTypeDynamicZoneInput: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type AuditLog = {
  __typename?: "AuditLog";
  action?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Scalars["JSON"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  params?: Maybe<Scalars["JSON"]["output"]>;
  request?: Maybe<Scalars["JSON"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AuditLogEntity = {
  __typename?: "AuditLogEntity";
  attributes?: Maybe<AuditLog>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type AuditLogEntityResponse = {
  __typename?: "AuditLogEntityResponse";
  data?: Maybe<AuditLogEntity>;
};

export type AuditLogEntityResponseCollection = {
  __typename?: "AuditLogEntityResponseCollection";
  data: Array<AuditLogEntity>;
  meta: ResponseCollectionMeta;
};

export type AuditLogFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<AuditLogFiltersInput>>>;
  content?: InputMaybe<JsonFilterInput>;
  contentType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<AuditLogFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AuditLogFiltersInput>>>;
  params?: InputMaybe<JsonFilterInput>;
  request?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type AuditLogInput = {
  action?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["JSON"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  request?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type Author = {
  __typename?: "Author";
  bio?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<UploadFileEntityResponse>;
  location?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  twitterHandle?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AuthorEntity = {
  __typename?: "AuthorEntity";
  attributes?: Maybe<Author>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type AuthorEntityResponse = {
  __typename?: "AuthorEntityResponse";
  data?: Maybe<AuthorEntity>;
};

export type AuthorEntityResponseCollection = {
  __typename?: "AuthorEntityResponseCollection";
  data: Array<AuthorEntity>;
  meta: ResponseCollectionMeta;
};

export type AuthorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<AuthorFiltersInput>>>;
  bio?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  location?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<AuthorFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AuthorFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  twitterHandle?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type AuthorInput = {
  bio?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  twitterHandle?: InputMaybe<Scalars["String"]["input"]>;
};

export type BlogPost = {
  __typename?: "BlogPost";
  author?: Maybe<AuthorEntityResponse>;
  category?: Maybe<CategoryBlogPostEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt: Scalars["String"]["output"];
  featuredImage: UploadFileEntityResponse;
  featuredOrder?: Maybe<Scalars["Int"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<BlogPostRelationResponseCollection>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  post: Scalars["String"]["output"];
  publishDate: Scalars["DateTime"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  tags?: Maybe<BlogPostTagRelationResponseCollection>;
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BlogPostLocalizationsArgs = {
  filters?: InputMaybe<BlogPostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type BlogPostTagsArgs = {
  filters?: InputMaybe<BlogPostTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type BlogPostEntity = {
  __typename?: "BlogPostEntity";
  attributes?: Maybe<BlogPost>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type BlogPostEntityResponse = {
  __typename?: "BlogPostEntityResponse";
  data?: Maybe<BlogPostEntity>;
};

export type BlogPostEntityResponseCollection = {
  __typename?: "BlogPostEntityResponseCollection";
  data: Array<BlogPostEntity>;
  meta: ResponseCollectionMeta;
};

export type BlogPostFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BlogPostFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  category?: InputMaybe<CategoryBlogPostFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  featuredOrder?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isFeatured?: InputMaybe<BooleanFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<BlogPostFiltersInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BlogPostFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BlogPostFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<BlogPostTagFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BlogPostInput = {
  author?: InputMaybe<Scalars["ID"]["input"]>;
  category?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  featuredOrder?: InputMaybe<Scalars["Int"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type BlogPostRelationResponseCollection = {
  __typename?: "BlogPostRelationResponseCollection";
  data: Array<BlogPostEntity>;
};

export type BlogPostTag = {
  __typename?: "BlogPostTag";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<UploadFileEntityResponse>;
  name: Scalars["String"]["output"];
  uid: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BlogPostTagEntity = {
  __typename?: "BlogPostTagEntity";
  attributes?: Maybe<BlogPostTag>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type BlogPostTagEntityResponse = {
  __typename?: "BlogPostTagEntityResponse";
  data?: Maybe<BlogPostTagEntity>;
};

export type BlogPostTagEntityResponseCollection = {
  __typename?: "BlogPostTagEntityResponseCollection";
  data: Array<BlogPostTagEntity>;
  meta: ResponseCollectionMeta;
};

export type BlogPostTagFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BlogPostTagFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BlogPostTagFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BlogPostTagFiltersInput>>>;
  uid?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BlogPostTagInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  uid?: InputMaybe<Scalars["String"]["input"]>;
};

export type BlogPostTagRelationResponseCollection = {
  __typename?: "BlogPostTagRelationResponseCollection";
  data: Array<BlogPostTagEntity>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  contains?: InputMaybe<Scalars["Boolean"]["input"]>;
  containsi?: InputMaybe<Scalars["Boolean"]["input"]>;
  endsWith?: InputMaybe<Scalars["Boolean"]["input"]>;
  eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  eqi?: InputMaybe<Scalars["Boolean"]["input"]>;
  gt?: InputMaybe<Scalars["Boolean"]["input"]>;
  gte?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  lt?: InputMaybe<Scalars["Boolean"]["input"]>;
  lte?: InputMaybe<Scalars["Boolean"]["input"]>;
  ne?: InputMaybe<Scalars["Boolean"]["input"]>;
  nei?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars["Boolean"]["input"]>;
  notContainsi?: InputMaybe<Scalars["Boolean"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Carrier = {
  __typename?: "Carrier";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<CarrierRelationResponseCollection>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CarrierLocalizationsArgs = {
  filters?: InputMaybe<CarrierFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CarrierEntity = {
  __typename?: "CarrierEntity";
  attributes?: Maybe<Carrier>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CarrierEntityResponse = {
  __typename?: "CarrierEntityResponse";
  data?: Maybe<CarrierEntity>;
};

export type CarrierEntityResponseCollection = {
  __typename?: "CarrierEntityResponseCollection";
  data: Array<CarrierEntity>;
  meta: ResponseCollectionMeta;
};

export type CarrierFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarrierFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarrierFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CarrierFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarrierFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarrierInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type CarrierRelationResponseCollection = {
  __typename?: "CarrierRelationResponseCollection";
  data: Array<CarrierEntity>;
};

export type CaseStudy = {
  __typename?: "CaseStudy";
  author?: Maybe<AuthorEntityResponse>;
  category?: Maybe<CategoryCaseStudyEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<UploadFileEntityResponse>;
  featuredOrder?: Maybe<Scalars["Int"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<CaseStudyRelationResponseCollection>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  post?: Maybe<Scalars["String"]["output"]>;
  publishDate?: Maybe<Scalars["DateTime"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  tags?: Maybe<BlogPostTagRelationResponseCollection>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CaseStudyLocalizationsArgs = {
  filters?: InputMaybe<CaseStudyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseStudyTagsArgs = {
  filters?: InputMaybe<BlogPostTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseStudyEntity = {
  __typename?: "CaseStudyEntity";
  attributes?: Maybe<CaseStudy>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CaseStudyEntityResponse = {
  __typename?: "CaseStudyEntityResponse";
  data?: Maybe<CaseStudyEntity>;
};

export type CaseStudyEntityResponseCollection = {
  __typename?: "CaseStudyEntityResponseCollection";
  data: Array<CaseStudyEntity>;
  meta: ResponseCollectionMeta;
};

export type CaseStudyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CaseStudyFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  category?: InputMaybe<CategoryCaseStudyFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  featuredOrder?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isFeatured?: InputMaybe<BooleanFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CaseStudyFiltersInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CaseStudyFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CaseStudyFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<BlogPostTagFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CaseStudyInput = {
  author?: InputMaybe<Scalars["ID"]["input"]>;
  category?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  featuredOrder?: InputMaybe<Scalars["Int"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type CaseStudyRelationResponseCollection = {
  __typename?: "CaseStudyRelationResponseCollection";
  data: Array<CaseStudyEntity>;
};

export type CategoryBlogPost = {
  __typename?: "CategoryBlogPost";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryBlogPostEntity = {
  __typename?: "CategoryBlogPostEntity";
  attributes?: Maybe<CategoryBlogPost>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CategoryBlogPostEntityResponse = {
  __typename?: "CategoryBlogPostEntityResponse";
  data?: Maybe<CategoryBlogPostEntity>;
};

export type CategoryBlogPostEntityResponseCollection = {
  __typename?: "CategoryBlogPostEntityResponseCollection";
  data: Array<CategoryBlogPostEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryBlogPostFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryBlogPostFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryBlogPostFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryBlogPostFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryBlogPostInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type CategoryCaseStudy = {
  __typename?: "CategoryCaseStudy";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryCaseStudyEntity = {
  __typename?: "CategoryCaseStudyEntity";
  attributes?: Maybe<CategoryCaseStudy>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CategoryCaseStudyEntityResponse = {
  __typename?: "CategoryCaseStudyEntityResponse";
  data?: Maybe<CategoryCaseStudyEntity>;
};

export type CategoryCaseStudyEntityResponseCollection = {
  __typename?: "CategoryCaseStudyEntityResponseCollection";
  data: Array<CategoryCaseStudyEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryCaseStudyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryCaseStudyFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryCaseStudyFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryCaseStudyFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryCaseStudyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type CategoryEvent = {
  __typename?: "CategoryEvent";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryEventEntity = {
  __typename?: "CategoryEventEntity";
  attributes?: Maybe<CategoryEvent>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CategoryEventEntityResponse = {
  __typename?: "CategoryEventEntityResponse";
  data?: Maybe<CategoryEventEntity>;
};

export type CategoryEventEntityResponseCollection = {
  __typename?: "CategoryEventEntityResponseCollection";
  data: Array<CategoryEventEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryEventFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryEventFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryEventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryEventFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryEventInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type CategoryNewsAndEvent = {
  __typename?: "CategoryNewsAndEvent";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryNewsAndEventEntity = {
  __typename?: "CategoryNewsAndEventEntity";
  attributes?: Maybe<CategoryNewsAndEvent>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CategoryNewsAndEventEntityResponse = {
  __typename?: "CategoryNewsAndEventEntityResponse";
  data?: Maybe<CategoryNewsAndEventEntity>;
};

export type CategoryNewsAndEventEntityResponseCollection = {
  __typename?: "CategoryNewsAndEventEntityResponseCollection";
  data: Array<CategoryNewsAndEventEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryNewsAndEventFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryNewsAndEventFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryNewsAndEventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryNewsAndEventFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryNewsAndEventInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type CategoryPage = {
  __typename?: "CategoryPage";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryPageEntity = {
  __typename?: "CategoryPageEntity";
  attributes?: Maybe<CategoryPage>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CategoryPageEntityResponse = {
  __typename?: "CategoryPageEntityResponse";
  data?: Maybe<CategoryPageEntity>;
};

export type CategoryPageEntityResponseCollection = {
  __typename?: "CategoryPageEntityResponseCollection";
  data: Array<CategoryPageEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryPageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryPageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryPageInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompanyType = {
  __typename?: "CompanyType";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CompanyTypeEntity = {
  __typename?: "CompanyTypeEntity";
  attributes?: Maybe<CompanyType>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type CompanyTypeEntityResponse = {
  __typename?: "CompanyTypeEntityResponse";
  data?: Maybe<CompanyTypeEntity>;
};

export type CompanyTypeEntityResponseCollection = {
  __typename?: "CompanyTypeEntityResponseCollection";
  data: Array<CompanyTypeEntity>;
  meta: ResponseCollectionMeta;
};

export type CompanyTypeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CompanyTypeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CompanyTypeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CompanyTypeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CompanyTypeInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CompanyTypeRelationResponseCollection = {
  __typename?: "CompanyTypeRelationResponseCollection";
  data: Array<CompanyTypeEntity>;
};

export type ComponentSupportHubResourceLink = {
  __typename?: "ComponentSupportHubResourceLink";
  URL: Scalars["String"]["output"];
  heroImage: UploadFileEntityResponse;
  id: Scalars["ID"]["output"];
};

export type ComponentSupportHubResourceVimeoVideo = {
  __typename?: "ComponentSupportHubResourceVimeoVideo";
  id: Scalars["ID"]["output"];
  imagePreview: UploadFileEntityResponse;
  imagePreviewLogo?: Maybe<UploadFileEntityResponse>;
  videoPreview: UploadFileEntityResponse;
  vimeoId: Scalars["String"]["output"];
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  contains?: InputMaybe<Scalars["Date"]["input"]>;
  containsi?: InputMaybe<Scalars["Date"]["input"]>;
  endsWith?: InputMaybe<Scalars["Date"]["input"]>;
  eq?: InputMaybe<Scalars["Date"]["input"]>;
  eqi?: InputMaybe<Scalars["Date"]["input"]>;
  gt?: InputMaybe<Scalars["Date"]["input"]>;
  gte?: InputMaybe<Scalars["Date"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  lt?: InputMaybe<Scalars["Date"]["input"]>;
  lte?: InputMaybe<Scalars["Date"]["input"]>;
  ne?: InputMaybe<Scalars["Date"]["input"]>;
  nei?: InputMaybe<Scalars["Date"]["input"]>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars["Date"]["input"]>;
  notContainsi?: InputMaybe<Scalars["Date"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["Date"]["input"]>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  contains?: InputMaybe<Scalars["DateTime"]["input"]>;
  containsi?: InputMaybe<Scalars["DateTime"]["input"]>;
  endsWith?: InputMaybe<Scalars["DateTime"]["input"]>;
  eq?: InputMaybe<Scalars["DateTime"]["input"]>;
  eqi?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  ne?: InputMaybe<Scalars["DateTime"]["input"]>;
  nei?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars["DateTime"]["input"]>;
  notContainsi?: InputMaybe<Scalars["DateTime"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EmailDesignerEmailTemplate = {
  __typename?: "EmailDesignerEmailTemplate";
  bodyHtml?: Maybe<Scalars["String"]["output"]>;
  bodyText?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  design?: Maybe<Scalars["JSON"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  subject?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["JSON"]["output"]>;
  templateReferenceId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type EmailDesignerEmailTemplateEntity = {
  __typename?: "EmailDesignerEmailTemplateEntity";
  attributes?: Maybe<EmailDesignerEmailTemplate>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type EmailDesignerEmailTemplateEntityResponse = {
  __typename?: "EmailDesignerEmailTemplateEntityResponse";
  data?: Maybe<EmailDesignerEmailTemplateEntity>;
};

export type EmailDesignerEmailTemplateEntityResponseCollection = {
  __typename?: "EmailDesignerEmailTemplateEntityResponseCollection";
  data: Array<EmailDesignerEmailTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailDesignerEmailTemplateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EmailDesignerEmailTemplateFiltersInput>>>;
  bodyHtml?: InputMaybe<StringFilterInput>;
  bodyText?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  design?: InputMaybe<JsonFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EmailDesignerEmailTemplateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EmailDesignerEmailTemplateFiltersInput>>>;
  subject?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<JsonFilterInput>;
  templateReferenceId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EmailDesignerEmailTemplateInput = {
  bodyHtml?: InputMaybe<Scalars["String"]["input"]>;
  bodyText?: InputMaybe<Scalars["String"]["input"]>;
  design?: InputMaybe<Scalars["JSON"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  templateReferenceId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Error = {
  __typename?: "Error";
  code: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
};

export type Event = {
  __typename?: "Event";
  author?: Maybe<AuthorEntityResponse>;
  category?: Maybe<CategoryEventEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<UploadFileEntityResponse>;
  featuredOrder?: Maybe<Scalars["Int"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<EventRelationResponseCollection>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  post?: Maybe<Scalars["String"]["output"]>;
  publishDate?: Maybe<Scalars["DateTime"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  tags?: Maybe<BlogPostTagRelationResponseCollection>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type EventLocalizationsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type EventTagsArgs = {
  filters?: InputMaybe<BlogPostTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type EventEntity = {
  __typename?: "EventEntity";
  attributes?: Maybe<Event>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type EventEntityResponse = {
  __typename?: "EventEntityResponse";
  data?: Maybe<EventEntity>;
};

export type EventEntityResponseCollection = {
  __typename?: "EventEntityResponseCollection";
  data: Array<EventEntity>;
  meta: ResponseCollectionMeta;
};

export type EventFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  category?: InputMaybe<CategoryEventFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  featuredOrder?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isFeatured?: InputMaybe<BooleanFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<EventFiltersInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<BlogPostTagFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EventInput = {
  author?: InputMaybe<Scalars["ID"]["input"]>;
  category?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  featuredOrder?: InputMaybe<Scalars["Int"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventRelationResponseCollection = {
  __typename?: "EventRelationResponseCollection";
  data: Array<EventEntity>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars["String"]["input"]>;
  caption?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FilterAudience = {
  __typename?: "FilterAudience";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterAudienceEntity = {
  __typename?: "FilterAudienceEntity";
  attributes?: Maybe<FilterAudience>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterAudienceEntityResponse = {
  __typename?: "FilterAudienceEntityResponse";
  data?: Maybe<FilterAudienceEntity>;
};

export type FilterAudienceEntityResponseCollection = {
  __typename?: "FilterAudienceEntityResponseCollection";
  data: Array<FilterAudienceEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterAudienceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterAudienceFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterAudienceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterAudienceFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterAudienceInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterAudienceRelationResponseCollection = {
  __typename?: "FilterAudienceRelationResponseCollection";
  data: Array<FilterAudienceEntity>;
};

export type FilterBusinessGoal = {
  __typename?: "FilterBusinessGoal";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterBusinessGoalEntity = {
  __typename?: "FilterBusinessGoalEntity";
  attributes?: Maybe<FilterBusinessGoal>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterBusinessGoalEntityResponse = {
  __typename?: "FilterBusinessGoalEntityResponse";
  data?: Maybe<FilterBusinessGoalEntity>;
};

export type FilterBusinessGoalEntityResponseCollection = {
  __typename?: "FilterBusinessGoalEntityResponseCollection";
  data: Array<FilterBusinessGoalEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterBusinessGoalFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterBusinessGoalFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterBusinessGoalFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterBusinessGoalFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterBusinessGoalInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterBusinessGoalRelationResponseCollection = {
  __typename?: "FilterBusinessGoalRelationResponseCollection";
  data: Array<FilterBusinessGoalEntity>;
};

export type FilterContentType = {
  __typename?: "FilterContentType";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterContentTypeEntity = {
  __typename?: "FilterContentTypeEntity";
  attributes?: Maybe<FilterContentType>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterContentTypeEntityResponse = {
  __typename?: "FilterContentTypeEntityResponse";
  data?: Maybe<FilterContentTypeEntity>;
};

export type FilterContentTypeEntityResponseCollection = {
  __typename?: "FilterContentTypeEntityResponseCollection";
  data: Array<FilterContentTypeEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterContentTypeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterContentTypeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterContentTypeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterContentTypeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterContentTypeInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterContentTypeRelationResponseCollection = {
  __typename?: "FilterContentTypeRelationResponseCollection";
  data: Array<FilterContentTypeEntity>;
};

export type FilterDealStage = {
  __typename?: "FilterDealStage";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterDealStageEntity = {
  __typename?: "FilterDealStageEntity";
  attributes?: Maybe<FilterDealStage>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterDealStageEntityResponse = {
  __typename?: "FilterDealStageEntityResponse";
  data?: Maybe<FilterDealStageEntity>;
};

export type FilterDealStageEntityResponseCollection = {
  __typename?: "FilterDealStageEntityResponseCollection";
  data: Array<FilterDealStageEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterDealStageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterDealStageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterDealStageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterDealStageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterDealStageInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterDealStageRelationResponseCollection = {
  __typename?: "FilterDealStageRelationResponseCollection";
  data: Array<FilterDealStageEntity>;
};

export type FilterEmployee = {
  __typename?: "FilterEmployee";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterEmployeeEntity = {
  __typename?: "FilterEmployeeEntity";
  attributes?: Maybe<FilterEmployee>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterEmployeeEntityResponse = {
  __typename?: "FilterEmployeeEntityResponse";
  data?: Maybe<FilterEmployeeEntity>;
};

export type FilterEmployeeEntityResponseCollection = {
  __typename?: "FilterEmployeeEntityResponseCollection";
  data: Array<FilterEmployeeEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterEmployeeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterEmployeeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterEmployeeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterEmployeeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterEmployeeInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterEmployeeRelationResponseCollection = {
  __typename?: "FilterEmployeeRelationResponseCollection";
  data: Array<FilterEmployeeEntity>;
};

export type FilterIndustrySector = {
  __typename?: "FilterIndustrySector";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterIndustrySectorEntity = {
  __typename?: "FilterIndustrySectorEntity";
  attributes?: Maybe<FilterIndustrySector>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterIndustrySectorEntityResponse = {
  __typename?: "FilterIndustrySectorEntityResponse";
  data?: Maybe<FilterIndustrySectorEntity>;
};

export type FilterIndustrySectorEntityResponseCollection = {
  __typename?: "FilterIndustrySectorEntityResponseCollection";
  data: Array<FilterIndustrySectorEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterIndustrySectorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterIndustrySectorFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterIndustrySectorFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterIndustrySectorFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterIndustrySectorInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterIndustrySectorRelationResponseCollection = {
  __typename?: "FilterIndustrySectorRelationResponseCollection";
  data: Array<FilterIndustrySectorEntity>;
};

export type FilterProduct = {
  __typename?: "FilterProduct";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterProductEntity = {
  __typename?: "FilterProductEntity";
  attributes?: Maybe<FilterProduct>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterProductEntityResponse = {
  __typename?: "FilterProductEntityResponse";
  data?: Maybe<FilterProductEntity>;
};

export type FilterProductEntityResponseCollection = {
  __typename?: "FilterProductEntityResponseCollection";
  data: Array<FilterProductEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterProductFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterProductFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterProductFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterProductFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterProductInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterProvider = {
  __typename?: "FilterProvider";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterProviderEntity = {
  __typename?: "FilterProviderEntity";
  attributes?: Maybe<FilterProvider>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterProviderEntityResponse = {
  __typename?: "FilterProviderEntityResponse";
  data?: Maybe<FilterProviderEntity>;
};

export type FilterProviderEntityResponseCollection = {
  __typename?: "FilterProviderEntityResponseCollection";
  data: Array<FilterProviderEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterProviderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterProviderFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterProviderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterProviderFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterProviderInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterProviderRelationResponseCollection = {
  __typename?: "FilterProviderRelationResponseCollection";
  data: Array<FilterProviderEntity>;
};

export type FilterRegion = {
  __typename?: "FilterRegion";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterRegionEntity = {
  __typename?: "FilterRegionEntity";
  attributes?: Maybe<FilterRegion>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterRegionEntityResponse = {
  __typename?: "FilterRegionEntityResponse";
  data?: Maybe<FilterRegionEntity>;
};

export type FilterRegionEntityResponseCollection = {
  __typename?: "FilterRegionEntityResponseCollection";
  data: Array<FilterRegionEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterRegionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterRegionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterRegionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterRegionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterRegionInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterRegionRelationResponseCollection = {
  __typename?: "FilterRegionRelationResponseCollection";
  data: Array<FilterRegionEntity>;
};

export type FilterWorkingArrangement = {
  __typename?: "FilterWorkingArrangement";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type FilterWorkingArrangementEntity = {
  __typename?: "FilterWorkingArrangementEntity";
  attributes?: Maybe<FilterWorkingArrangement>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type FilterWorkingArrangementEntityResponse = {
  __typename?: "FilterWorkingArrangementEntityResponse";
  data?: Maybe<FilterWorkingArrangementEntity>;
};

export type FilterWorkingArrangementEntityResponseCollection = {
  __typename?: "FilterWorkingArrangementEntityResponseCollection";
  data: Array<FilterWorkingArrangementEntity>;
  meta: ResponseCollectionMeta;
};

export type FilterWorkingArrangementFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FilterWorkingArrangementFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FilterWorkingArrangementFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FilterWorkingArrangementFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FilterWorkingArrangementInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FilterWorkingArrangementRelationResponseCollection = {
  __typename?: "FilterWorkingArrangementRelationResponseCollection";
  data: Array<FilterWorkingArrangementEntity>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  contains?: InputMaybe<Scalars["Float"]["input"]>;
  containsi?: InputMaybe<Scalars["Float"]["input"]>;
  endsWith?: InputMaybe<Scalars["Float"]["input"]>;
  eq?: InputMaybe<Scalars["Float"]["input"]>;
  eqi?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  ne?: InputMaybe<Scalars["Float"]["input"]>;
  nei?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars["Float"]["input"]>;
  notContainsi?: InputMaybe<Scalars["Float"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["Float"]["input"]>;
};

export type GenericMorph =
  | AuditLog
  | Author
  | BlogPost
  | BlogPostTag
  | Carrier
  | CaseStudy
  | CategoryBlogPost
  | CategoryCaseStudy
  | CategoryEvent
  | CategoryNewsAndEvent
  | CategoryPage
  | CompanyType
  | ComponentSupportHubResourceLink
  | ComponentSupportHubResourceVimeoVideo
  | EmailDesignerEmailTemplate
  | Event
  | FilterAudience
  | FilterBusinessGoal
  | FilterContentType
  | FilterDealStage
  | FilterEmployee
  | FilterIndustrySector
  | FilterProduct
  | FilterProvider
  | FilterRegion
  | FilterWorkingArrangement
  | GettingStartedSection
  | GettingStartedTask
  | I18NLocale
  | LegalPage
  | NewsAndEvent
  | Page
  | PortalArticle
  | PortalResource
  | PortalResourceEapOption
  | PortalResourceSection
  | ProductType
  | Region
  | SupportHubResource
  | SupportHubSection
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | Video;

export type GenericPropOptions = {
  hasKeyResources?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasLatestCaseStudy?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GenericProps = {
  __typename?: "GenericProps";
  keyResources?: Maybe<Array<Maybe<MiniResource>>>;
  latestCaseStudy?: Maybe<MiniResource>;
};

export type GettingStartedSection = {
  __typename?: "GettingStartedSection";
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<GettingStartedSectionRelationResponseCollection>;
  order?: Maybe<Scalars["Int"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  stepId: Scalars["String"]["output"];
  tasks?: Maybe<GettingStartedTaskRelationResponseCollection>;
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GettingStartedSectionLocalizationsArgs = {
  filters?: InputMaybe<GettingStartedSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type GettingStartedSectionTasksArgs = {
  filters?: InputMaybe<GettingStartedTaskFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type GettingStartedSectionEntity = {
  __typename?: "GettingStartedSectionEntity";
  attributes?: Maybe<GettingStartedSection>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type GettingStartedSectionEntityResponse = {
  __typename?: "GettingStartedSectionEntityResponse";
  data?: Maybe<GettingStartedSectionEntity>;
};

export type GettingStartedSectionEntityResponseCollection = {
  __typename?: "GettingStartedSectionEntityResponseCollection";
  data: Array<GettingStartedSectionEntity>;
  meta: ResponseCollectionMeta;
};

export type GettingStartedSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GettingStartedSectionFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<GettingStartedSectionFiltersInput>;
  not?: InputMaybe<GettingStartedSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GettingStartedSectionFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  stepId?: InputMaybe<StringFilterInput>;
  tasks?: InputMaybe<GettingStartedTaskFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GettingStartedSectionInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  stepId?: InputMaybe<Scalars["String"]["input"]>;
  tasks?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type GettingStartedSectionRelationResponseCollection = {
  __typename?: "GettingStartedSectionRelationResponseCollection";
  data: Array<GettingStartedSectionEntity>;
};

export type GettingStartedTask = {
  __typename?: "GettingStartedTask";
  body: Scalars["String"]["output"];
  companyTypes?: Maybe<CompanyTypeRelationResponseCollection>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<GettingStartedTaskRelationResponseCollection>;
  order?: Maybe<Scalars["Int"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  section?: Maybe<GettingStartedSectionEntityResponse>;
  taskId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GettingStartedTaskCompanyTypesArgs = {
  filters?: InputMaybe<CompanyTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type GettingStartedTaskLocalizationsArgs = {
  filters?: InputMaybe<GettingStartedTaskFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type GettingStartedTaskEntity = {
  __typename?: "GettingStartedTaskEntity";
  attributes?: Maybe<GettingStartedTask>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type GettingStartedTaskEntityResponse = {
  __typename?: "GettingStartedTaskEntityResponse";
  data?: Maybe<GettingStartedTaskEntity>;
};

export type GettingStartedTaskEntityResponseCollection = {
  __typename?: "GettingStartedTaskEntityResponseCollection";
  data: Array<GettingStartedTaskEntity>;
  meta: ResponseCollectionMeta;
};

export type GettingStartedTaskFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GettingStartedTaskFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  companyTypes?: InputMaybe<CompanyTypeFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<GettingStartedTaskFiltersInput>;
  not?: InputMaybe<GettingStartedTaskFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GettingStartedTaskFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  section?: InputMaybe<GettingStartedSectionFiltersInput>;
  taskId?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GettingStartedTaskInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  companyTypes?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  section?: InputMaybe<Scalars["ID"]["input"]>;
  taskId?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type GettingStartedTaskRelationResponseCollection = {
  __typename?: "GettingStartedTaskRelationResponseCollection";
  data: Array<GettingStartedTaskEntity>;
};

export type I18NLocale = {
  __typename?: "I18NLocale";
  code?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type I18NLocaleEntity = {
  __typename?: "I18NLocaleEntity";
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type I18NLocaleEntityResponse = {
  __typename?: "I18NLocaleEntityResponse";
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: "I18NLocaleEntityResponseCollection";
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  contains?: InputMaybe<Scalars["ID"]["input"]>;
  containsi?: InputMaybe<Scalars["ID"]["input"]>;
  endsWith?: InputMaybe<Scalars["ID"]["input"]>;
  eq?: InputMaybe<Scalars["ID"]["input"]>;
  eqi?: InputMaybe<Scalars["ID"]["input"]>;
  gt?: InputMaybe<Scalars["ID"]["input"]>;
  gte?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  lt?: InputMaybe<Scalars["ID"]["input"]>;
  lte?: InputMaybe<Scalars["ID"]["input"]>;
  ne?: InputMaybe<Scalars["ID"]["input"]>;
  nei?: InputMaybe<Scalars["ID"]["input"]>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars["ID"]["input"]>;
  notContainsi?: InputMaybe<Scalars["ID"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["ID"]["input"]>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  contains?: InputMaybe<Scalars["Int"]["input"]>;
  containsi?: InputMaybe<Scalars["Int"]["input"]>;
  endsWith?: InputMaybe<Scalars["Int"]["input"]>;
  eq?: InputMaybe<Scalars["Int"]["input"]>;
  eqi?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  ne?: InputMaybe<Scalars["Int"]["input"]>;
  nei?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars["Int"]["input"]>;
  notContainsi?: InputMaybe<Scalars["Int"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  contains?: InputMaybe<Scalars["JSON"]["input"]>;
  containsi?: InputMaybe<Scalars["JSON"]["input"]>;
  endsWith?: InputMaybe<Scalars["JSON"]["input"]>;
  eq?: InputMaybe<Scalars["JSON"]["input"]>;
  eqi?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  ne?: InputMaybe<Scalars["JSON"]["input"]>;
  nei?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars["JSON"]["input"]>;
  notContainsi?: InputMaybe<Scalars["JSON"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type LegalPage = {
  __typename?: "LegalPage";
  author?: Maybe<AuthorEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt: Scalars["String"]["output"];
  featuredImage?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<LegalPageRelationResponseCollection>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  post: Scalars["String"]["output"];
  publishDate: Scalars["DateTime"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type LegalPageLocalizationsArgs = {
  filters?: InputMaybe<LegalPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type LegalPageEntity = {
  __typename?: "LegalPageEntity";
  attributes?: Maybe<LegalPage>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type LegalPageEntityResponse = {
  __typename?: "LegalPageEntityResponse";
  data?: Maybe<LegalPageEntity>;
};

export type LegalPageEntityResponseCollection = {
  __typename?: "LegalPageEntityResponseCollection";
  data: Array<LegalPageEntity>;
  meta: ResponseCollectionMeta;
};

export type LegalPageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LegalPageFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<LegalPageFiltersInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LegalPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LegalPageFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type LegalPageInput = {
  author?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type LegalPageRelationResponseCollection = {
  __typename?: "LegalPageRelationResponseCollection";
  data: Array<LegalPageEntity>;
};

export type MiniResource = {
  __typename?: "MiniResource";
  alt?: Maybe<Scalars["String"]["output"]>;
  img?: Maybe<Scalars["String"]["output"]>;
  readTime?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  tag?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createAuditLog?: Maybe<AuditLogEntityResponse>;
  createAuthor?: Maybe<AuthorEntityResponse>;
  createBlogPost?: Maybe<BlogPostEntityResponse>;
  createBlogPostLocalization?: Maybe<BlogPostEntityResponse>;
  createBlogPostTag?: Maybe<BlogPostTagEntityResponse>;
  createCarrier?: Maybe<CarrierEntityResponse>;
  createCarrierLocalization?: Maybe<CarrierEntityResponse>;
  createCaseStudy?: Maybe<CaseStudyEntityResponse>;
  createCaseStudyLocalization?: Maybe<CaseStudyEntityResponse>;
  createCategoryBlogPost?: Maybe<CategoryBlogPostEntityResponse>;
  createCategoryCaseStudy?: Maybe<CategoryCaseStudyEntityResponse>;
  createCategoryEvent?: Maybe<CategoryEventEntityResponse>;
  createCategoryNewsAndEvent?: Maybe<CategoryNewsAndEventEntityResponse>;
  createCategoryPage?: Maybe<CategoryPageEntityResponse>;
  createCompanyType?: Maybe<CompanyTypeEntityResponse>;
  createEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  createEvent?: Maybe<EventEntityResponse>;
  createEventLocalization?: Maybe<EventEntityResponse>;
  createFilterAudience?: Maybe<FilterAudienceEntityResponse>;
  createFilterBusinessGoal?: Maybe<FilterBusinessGoalEntityResponse>;
  createFilterContentType?: Maybe<FilterContentTypeEntityResponse>;
  createFilterDealStage?: Maybe<FilterDealStageEntityResponse>;
  createFilterEmployee?: Maybe<FilterEmployeeEntityResponse>;
  createFilterIndustrySector?: Maybe<FilterIndustrySectorEntityResponse>;
  createFilterProduct?: Maybe<FilterProductEntityResponse>;
  createFilterProvider?: Maybe<FilterProviderEntityResponse>;
  createFilterRegion?: Maybe<FilterRegionEntityResponse>;
  createFilterWorkingArrangement?: Maybe<FilterWorkingArrangementEntityResponse>;
  createGettingStartedSection?: Maybe<GettingStartedSectionEntityResponse>;
  createGettingStartedSectionLocalization?: Maybe<GettingStartedSectionEntityResponse>;
  createGettingStartedTask?: Maybe<GettingStartedTaskEntityResponse>;
  createGettingStartedTaskLocalization?: Maybe<GettingStartedTaskEntityResponse>;
  createLegalPage?: Maybe<LegalPageEntityResponse>;
  createLegalPageLocalization?: Maybe<LegalPageEntityResponse>;
  createNewsAndEvent?: Maybe<NewsAndEventEntityResponse>;
  createNewsAndEventLocalization?: Maybe<NewsAndEventEntityResponse>;
  createPage?: Maybe<PageEntityResponse>;
  createPageLocalization?: Maybe<PageEntityResponse>;
  createPortalArticle?: Maybe<PortalArticleEntityResponse>;
  createPortalArticleLocalization?: Maybe<PortalArticleEntityResponse>;
  createPortalResource?: Maybe<PortalResourceEntityResponse>;
  createPortalResourceEapOption?: Maybe<PortalResourceEapOptionEntityResponse>;
  createPortalResourceLocalization?: Maybe<PortalResourceEntityResponse>;
  createPortalResourceSection?: Maybe<PortalResourceSectionEntityResponse>;
  createPortalResourceSectionLocalization?: Maybe<PortalResourceSectionEntityResponse>;
  createProductType?: Maybe<ProductTypeEntityResponse>;
  createProductTypeLocalization?: Maybe<ProductTypeEntityResponse>;
  createRegion?: Maybe<RegionEntityResponse>;
  createSupportHubResource?: Maybe<SupportHubResourceEntityResponse>;
  createSupportHubSection?: Maybe<SupportHubSectionEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createVideo?: Maybe<VideoEntityResponse>;
  createVideoLocalization?: Maybe<VideoEntityResponse>;
  deleteAuditLog?: Maybe<AuditLogEntityResponse>;
  deleteAuthor?: Maybe<AuthorEntityResponse>;
  deleteBlogPost?: Maybe<BlogPostEntityResponse>;
  deleteBlogPostTag?: Maybe<BlogPostTagEntityResponse>;
  deleteCarrier?: Maybe<CarrierEntityResponse>;
  deleteCaseStudy?: Maybe<CaseStudyEntityResponse>;
  deleteCategoryBlogPost?: Maybe<CategoryBlogPostEntityResponse>;
  deleteCategoryCaseStudy?: Maybe<CategoryCaseStudyEntityResponse>;
  deleteCategoryEvent?: Maybe<CategoryEventEntityResponse>;
  deleteCategoryNewsAndEvent?: Maybe<CategoryNewsAndEventEntityResponse>;
  deleteCategoryPage?: Maybe<CategoryPageEntityResponse>;
  deleteCompanyType?: Maybe<CompanyTypeEntityResponse>;
  deleteEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  deleteEvent?: Maybe<EventEntityResponse>;
  deleteFilterAudience?: Maybe<FilterAudienceEntityResponse>;
  deleteFilterBusinessGoal?: Maybe<FilterBusinessGoalEntityResponse>;
  deleteFilterContentType?: Maybe<FilterContentTypeEntityResponse>;
  deleteFilterDealStage?: Maybe<FilterDealStageEntityResponse>;
  deleteFilterEmployee?: Maybe<FilterEmployeeEntityResponse>;
  deleteFilterIndustrySector?: Maybe<FilterIndustrySectorEntityResponse>;
  deleteFilterProduct?: Maybe<FilterProductEntityResponse>;
  deleteFilterProvider?: Maybe<FilterProviderEntityResponse>;
  deleteFilterRegion?: Maybe<FilterRegionEntityResponse>;
  deleteFilterWorkingArrangement?: Maybe<FilterWorkingArrangementEntityResponse>;
  deleteGettingStartedSection?: Maybe<GettingStartedSectionEntityResponse>;
  deleteGettingStartedTask?: Maybe<GettingStartedTaskEntityResponse>;
  deleteLegalPage?: Maybe<LegalPageEntityResponse>;
  deleteNewsAndEvent?: Maybe<NewsAndEventEntityResponse>;
  deletePage?: Maybe<PageEntityResponse>;
  deletePortalArticle?: Maybe<PortalArticleEntityResponse>;
  deletePortalResource?: Maybe<PortalResourceEntityResponse>;
  deletePortalResourceEapOption?: Maybe<PortalResourceEapOptionEntityResponse>;
  deletePortalResourceSection?: Maybe<PortalResourceSectionEntityResponse>;
  deleteProductType?: Maybe<ProductTypeEntityResponse>;
  deleteRegion?: Maybe<RegionEntityResponse>;
  deleteSupportHubResource?: Maybe<SupportHubResourceEntityResponse>;
  deleteSupportHubSection?: Maybe<SupportHubSectionEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteVideo?: Maybe<VideoEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateAuditLog?: Maybe<AuditLogEntityResponse>;
  updateAuthor?: Maybe<AuthorEntityResponse>;
  updateBlogPost?: Maybe<BlogPostEntityResponse>;
  updateBlogPostTag?: Maybe<BlogPostTagEntityResponse>;
  updateCarrier?: Maybe<CarrierEntityResponse>;
  updateCaseStudy?: Maybe<CaseStudyEntityResponse>;
  updateCategoryBlogPost?: Maybe<CategoryBlogPostEntityResponse>;
  updateCategoryCaseStudy?: Maybe<CategoryCaseStudyEntityResponse>;
  updateCategoryEvent?: Maybe<CategoryEventEntityResponse>;
  updateCategoryNewsAndEvent?: Maybe<CategoryNewsAndEventEntityResponse>;
  updateCategoryPage?: Maybe<CategoryPageEntityResponse>;
  updateCompanyType?: Maybe<CompanyTypeEntityResponse>;
  updateEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  updateEvent?: Maybe<EventEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateFilterAudience?: Maybe<FilterAudienceEntityResponse>;
  updateFilterBusinessGoal?: Maybe<FilterBusinessGoalEntityResponse>;
  updateFilterContentType?: Maybe<FilterContentTypeEntityResponse>;
  updateFilterDealStage?: Maybe<FilterDealStageEntityResponse>;
  updateFilterEmployee?: Maybe<FilterEmployeeEntityResponse>;
  updateFilterIndustrySector?: Maybe<FilterIndustrySectorEntityResponse>;
  updateFilterProduct?: Maybe<FilterProductEntityResponse>;
  updateFilterProvider?: Maybe<FilterProviderEntityResponse>;
  updateFilterRegion?: Maybe<FilterRegionEntityResponse>;
  updateFilterWorkingArrangement?: Maybe<FilterWorkingArrangementEntityResponse>;
  updateGettingStartedSection?: Maybe<GettingStartedSectionEntityResponse>;
  updateGettingStartedTask?: Maybe<GettingStartedTaskEntityResponse>;
  updateLegalPage?: Maybe<LegalPageEntityResponse>;
  updateNewsAndEvent?: Maybe<NewsAndEventEntityResponse>;
  updatePage?: Maybe<PageEntityResponse>;
  updatePortalArticle?: Maybe<PortalArticleEntityResponse>;
  updatePortalResource?: Maybe<PortalResourceEntityResponse>;
  updatePortalResourceEapOption?: Maybe<PortalResourceEapOptionEntityResponse>;
  updatePortalResourceSection?: Maybe<PortalResourceSectionEntityResponse>;
  updateProductType?: Maybe<ProductTypeEntityResponse>;
  updateRegion?: Maybe<RegionEntityResponse>;
  updateSupportHubResource?: Maybe<SupportHubResourceEntityResponse>;
  updateSupportHubSection?: Maybe<SupportHubSectionEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateVideo?: Maybe<VideoEntityResponse>;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  passwordConfirmation: Scalars["String"]["input"];
};

export type MutationCreateAuditLogArgs = {
  data: AuditLogInput;
};

export type MutationCreateAuthorArgs = {
  data: AuthorInput;
};

export type MutationCreateBlogPostArgs = {
  data: BlogPostInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateBlogPostLocalizationArgs = {
  data?: InputMaybe<BlogPostInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateBlogPostTagArgs = {
  data: BlogPostTagInput;
};

export type MutationCreateCarrierArgs = {
  data: CarrierInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateCarrierLocalizationArgs = {
  data?: InputMaybe<CarrierInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateCaseStudyArgs = {
  data: CaseStudyInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateCaseStudyLocalizationArgs = {
  data?: InputMaybe<CaseStudyInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateCategoryBlogPostArgs = {
  data: CategoryBlogPostInput;
};

export type MutationCreateCategoryCaseStudyArgs = {
  data: CategoryCaseStudyInput;
};

export type MutationCreateCategoryEventArgs = {
  data: CategoryEventInput;
};

export type MutationCreateCategoryNewsAndEventArgs = {
  data: CategoryNewsAndEventInput;
};

export type MutationCreateCategoryPageArgs = {
  data: CategoryPageInput;
};

export type MutationCreateCompanyTypeArgs = {
  data: CompanyTypeInput;
};

export type MutationCreateEmailDesignerEmailTemplateArgs = {
  data: EmailDesignerEmailTemplateInput;
};

export type MutationCreateEventArgs = {
  data: EventInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateEventLocalizationArgs = {
  data?: InputMaybe<EventInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateFilterAudienceArgs = {
  data: FilterAudienceInput;
};

export type MutationCreateFilterBusinessGoalArgs = {
  data: FilterBusinessGoalInput;
};

export type MutationCreateFilterContentTypeArgs = {
  data: FilterContentTypeInput;
};

export type MutationCreateFilterDealStageArgs = {
  data: FilterDealStageInput;
};

export type MutationCreateFilterEmployeeArgs = {
  data: FilterEmployeeInput;
};

export type MutationCreateFilterIndustrySectorArgs = {
  data: FilterIndustrySectorInput;
};

export type MutationCreateFilterProductArgs = {
  data: FilterProductInput;
};

export type MutationCreateFilterProviderArgs = {
  data: FilterProviderInput;
};

export type MutationCreateFilterRegionArgs = {
  data: FilterRegionInput;
};

export type MutationCreateFilterWorkingArrangementArgs = {
  data: FilterWorkingArrangementInput;
};

export type MutationCreateGettingStartedSectionArgs = {
  data: GettingStartedSectionInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateGettingStartedSectionLocalizationArgs = {
  data?: InputMaybe<GettingStartedSectionInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateGettingStartedTaskArgs = {
  data: GettingStartedTaskInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateGettingStartedTaskLocalizationArgs = {
  data?: InputMaybe<GettingStartedTaskInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateLegalPageArgs = {
  data: LegalPageInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateLegalPageLocalizationArgs = {
  data?: InputMaybe<LegalPageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateNewsAndEventArgs = {
  data: NewsAndEventInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateNewsAndEventLocalizationArgs = {
  data?: InputMaybe<NewsAndEventInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePageArgs = {
  data: PageInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePageLocalizationArgs = {
  data?: InputMaybe<PageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePortalArticleArgs = {
  data: PortalArticleInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePortalArticleLocalizationArgs = {
  data?: InputMaybe<PortalArticleInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePortalResourceArgs = {
  data: PortalResourceInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePortalResourceEapOptionArgs = {
  data: PortalResourceEapOptionInput;
};

export type MutationCreatePortalResourceLocalizationArgs = {
  data?: InputMaybe<PortalResourceInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePortalResourceSectionArgs = {
  data: PortalResourceSectionInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreatePortalResourceSectionLocalizationArgs = {
  data?: InputMaybe<PortalResourceSectionInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateProductTypeArgs = {
  data: ProductTypeInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateProductTypeLocalizationArgs = {
  data?: InputMaybe<ProductTypeInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateRegionArgs = {
  data: RegionInput;
};

export type MutationCreateSupportHubResourceArgs = {
  data: SupportHubResourceInput;
};

export type MutationCreateSupportHubSectionArgs = {
  data: SupportHubSectionInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationCreateVideoArgs = {
  data: VideoInput;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationCreateVideoLocalizationArgs = {
  data?: InputMaybe<VideoInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteAuditLogArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAuthorArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteBlogPostArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteBlogPostTagArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCarrierArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteCaseStudyArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteCategoryBlogPostArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCategoryCaseStudyArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCategoryEventArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCategoryNewsAndEventArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCategoryPageArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCompanyTypeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteEmailDesignerEmailTemplateArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteEventArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteFilterAudienceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterBusinessGoalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterContentTypeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterDealStageArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterEmployeeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterIndustrySectorArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterProductArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterProviderArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterRegionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFilterWorkingArrangementArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteGettingStartedSectionArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteGettingStartedTaskArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteLegalPageArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteNewsAndEventArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeletePageArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeletePortalArticleArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeletePortalResourceArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeletePortalResourceEapOptionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePortalResourceSectionArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteProductTypeArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationDeleteRegionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteSupportHubResourceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteSupportHubSectionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteVideoArgs = {
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars["String"]["input"];
};

export type MutationForgotPasswordArgs = {
  email: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<InputMaybe<Scalars["Upload"]["input"]>>;
  ref?: InputMaybe<Scalars["String"]["input"]>;
  refId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationResetPasswordArgs = {
  code: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  passwordConfirmation: Scalars["String"]["input"];
};

export type MutationUpdateAuditLogArgs = {
  data: AuditLogInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateAuthorArgs = {
  data: AuthorInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateBlogPostArgs = {
  data: BlogPostInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateBlogPostTagArgs = {
  data: BlogPostTagInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCarrierArgs = {
  data: CarrierInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateCaseStudyArgs = {
  data: CaseStudyInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateCategoryBlogPostArgs = {
  data: CategoryBlogPostInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCategoryCaseStudyArgs = {
  data: CategoryCaseStudyInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCategoryEventArgs = {
  data: CategoryEventInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCategoryNewsAndEventArgs = {
  data: CategoryNewsAndEventInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCategoryPageArgs = {
  data: CategoryPageInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCompanyTypeArgs = {
  data: CompanyTypeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateEmailDesignerEmailTemplateArgs = {
  data: EmailDesignerEmailTemplateInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateEventArgs = {
  data: EventInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars["ID"]["input"];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFilterAudienceArgs = {
  data: FilterAudienceInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterBusinessGoalArgs = {
  data: FilterBusinessGoalInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterContentTypeArgs = {
  data: FilterContentTypeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterDealStageArgs = {
  data: FilterDealStageInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterEmployeeArgs = {
  data: FilterEmployeeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterIndustrySectorArgs = {
  data: FilterIndustrySectorInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterProductArgs = {
  data: FilterProductInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterProviderArgs = {
  data: FilterProviderInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterRegionArgs = {
  data: FilterRegionInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFilterWorkingArrangementArgs = {
  data: FilterWorkingArrangementInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateGettingStartedSectionArgs = {
  data: GettingStartedSectionInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateGettingStartedTaskArgs = {
  data: GettingStartedTaskInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateLegalPageArgs = {
  data: LegalPageInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateNewsAndEventArgs = {
  data: NewsAndEventInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdatePageArgs = {
  data: PageInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdatePortalArticleArgs = {
  data: PortalArticleInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdatePortalResourceArgs = {
  data: PortalResourceInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdatePortalResourceEapOptionArgs = {
  data: PortalResourceEapOptionInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdatePortalResourceSectionArgs = {
  data: PortalResourceSectionInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateProductTypeArgs = {
  data: ProductTypeInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUpdateRegionArgs = {
  data: RegionInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateSupportHubResourceArgs = {
  data: SupportHubResourceInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateSupportHubSectionArgs = {
  data: SupportHubSectionInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateVideoArgs = {
  data: VideoInput;
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars["String"]["input"]>;
  file: Scalars["Upload"]["input"];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars["String"]["input"]>;
  refId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type NewsAndEvent = {
  __typename?: "NewsAndEvent";
  author?: Maybe<AuthorEntityResponse>;
  category?: Maybe<CategoryNewsAndEventEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt: Scalars["String"]["output"];
  featuredImage: UploadFileEntityResponse;
  featuredOrder?: Maybe<Scalars["Int"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<NewsAndEventRelationResponseCollection>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  post: Scalars["String"]["output"];
  publishDate: Scalars["DateTime"]["output"];
  publishToSupportHub: Scalars["Boolean"]["output"];
  publishToYulife: Scalars["Boolean"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  tags?: Maybe<BlogPostTagRelationResponseCollection>;
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type NewsAndEventLocalizationsArgs = {
  filters?: InputMaybe<NewsAndEventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type NewsAndEventTagsArgs = {
  filters?: InputMaybe<BlogPostTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type NewsAndEventEntity = {
  __typename?: "NewsAndEventEntity";
  attributes?: Maybe<NewsAndEvent>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type NewsAndEventEntityResponse = {
  __typename?: "NewsAndEventEntityResponse";
  data?: Maybe<NewsAndEventEntity>;
};

export type NewsAndEventEntityResponseCollection = {
  __typename?: "NewsAndEventEntityResponseCollection";
  data: Array<NewsAndEventEntity>;
  meta: ResponseCollectionMeta;
};

export type NewsAndEventFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<NewsAndEventFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  category?: InputMaybe<CategoryNewsAndEventFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  featuredOrder?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isFeatured?: InputMaybe<BooleanFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<NewsAndEventFiltersInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<NewsAndEventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NewsAndEventFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishToSupportHub?: InputMaybe<BooleanFilterInput>;
  publishToYulife?: InputMaybe<BooleanFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<BlogPostTagFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NewsAndEventInput = {
  author?: InputMaybe<Scalars["ID"]["input"]>;
  category?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  featuredOrder?: InputMaybe<Scalars["Int"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishToSupportHub?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishToYulife?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type NewsAndEventRelationResponseCollection = {
  __typename?: "NewsAndEventRelationResponseCollection";
  data: Array<NewsAndEventEntity>;
};

export type Page = {
  __typename?: "Page";
  author?: Maybe<AuthorEntityResponse>;
  category?: Maybe<CategoryPageEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt: Scalars["String"]["output"];
  featuredImage?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<PageRelationResponseCollection>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  post: Scalars["String"]["output"];
  publishDate: Scalars["DateTime"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PageLocalizationsArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageEntity = {
  __typename?: "PageEntity";
  attributes?: Maybe<Page>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type PageEntityResponse = {
  __typename?: "PageEntityResponse";
  data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
  __typename?: "PageEntityResponseCollection";
  data: Array<PageEntity>;
  meta: ResponseCollectionMeta;
};

export type PageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  category?: InputMaybe<CategoryPageFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PageFiltersInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PageInput = {
  author?: InputMaybe<Scalars["ID"]["input"]>;
  category?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageRelationResponseCollection = {
  __typename?: "PageRelationResponseCollection";
  data: Array<PageEntity>;
};

export type Pagination = {
  __typename?: "Pagination";
  page: Scalars["Int"]["output"];
  pageCount: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  start?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PortalArticle = {
  __typename?: "PortalArticle";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt: Scalars["String"]["output"];
  featuredEndDate?: Maybe<Scalars["Date"]["output"]>;
  featuredStartDate: Scalars["Date"]["output"];
  image: UploadFileEntityResponse;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<PortalArticleRelationResponseCollection>;
  post: Scalars["String"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  uuid: Scalars["String"]["output"];
};

export type PortalArticleLocalizationsArgs = {
  filters?: InputMaybe<PortalArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalArticleEntity = {
  __typename?: "PortalArticleEntity";
  attributes?: Maybe<PortalArticle>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type PortalArticleEntityResponse = {
  __typename?: "PortalArticleEntityResponse";
  data?: Maybe<PortalArticleEntity>;
};

export type PortalArticleEntityResponseCollection = {
  __typename?: "PortalArticleEntityResponseCollection";
  data: Array<PortalArticleEntity>;
  meta: ResponseCollectionMeta;
};

export type PortalArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PortalArticleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  featuredEndDate?: InputMaybe<DateFilterInput>;
  featuredStartDate?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PortalArticleFiltersInput>;
  not?: InputMaybe<PortalArticleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PortalArticleFiltersInput>>>;
  post?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  uuid?: InputMaybe<StringFilterInput>;
};

export type PortalArticleInput = {
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  featuredStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  post?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type PortalArticleRelationResponseCollection = {
  __typename?: "PortalArticleRelationResponseCollection";
  data: Array<PortalArticleEntity>;
};

export type PortalResource = {
  __typename?: "PortalResource";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  eap?: Maybe<PortalResourceEapOptionRelationResponseCollection>;
  isSaasOnly?: Maybe<Scalars["Boolean"]["output"]>;
  label: Scalars["String"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<PortalResourceRelationResponseCollection>;
  name: Scalars["String"]["output"];
  productType?: Maybe<ProductTypeRelationResponseCollection>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  region?: Maybe<RegionRelationResponseCollection>;
  sections: PortalResourceSectionRelationResponseCollection;
  subheading?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  url: Scalars["String"]["output"];
};

export type PortalResourceEapArgs = {
  filters?: InputMaybe<PortalResourceEapOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceLocalizationsArgs = {
  filters?: InputMaybe<PortalResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceProductTypeArgs = {
  filters?: InputMaybe<ProductTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceRegionArgs = {
  filters?: InputMaybe<RegionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceSectionsArgs = {
  filters?: InputMaybe<PortalResourceSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceEapOption = {
  __typename?: "PortalResourceEapOption";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PortalResourceEapOptionEntity = {
  __typename?: "PortalResourceEapOptionEntity";
  attributes?: Maybe<PortalResourceEapOption>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type PortalResourceEapOptionEntityResponse = {
  __typename?: "PortalResourceEapOptionEntityResponse";
  data?: Maybe<PortalResourceEapOptionEntity>;
};

export type PortalResourceEapOptionEntityResponseCollection = {
  __typename?: "PortalResourceEapOptionEntityResponseCollection";
  data: Array<PortalResourceEapOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type PortalResourceEapOptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PortalResourceEapOptionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PortalResourceEapOptionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PortalResourceEapOptionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PortalResourceEapOptionInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type PortalResourceEapOptionRelationResponseCollection = {
  __typename?: "PortalResourceEapOptionRelationResponseCollection";
  data: Array<PortalResourceEapOptionEntity>;
};

export type PortalResourceEntity = {
  __typename?: "PortalResourceEntity";
  attributes?: Maybe<PortalResource>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type PortalResourceEntityResponse = {
  __typename?: "PortalResourceEntityResponse";
  data?: Maybe<PortalResourceEntity>;
};

export type PortalResourceEntityResponseCollection = {
  __typename?: "PortalResourceEntityResponseCollection";
  data: Array<PortalResourceEntity>;
  meta: ResponseCollectionMeta;
};

export type PortalResourceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PortalResourceFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  eap?: InputMaybe<PortalResourceEapOptionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  isSaasOnly?: InputMaybe<BooleanFilterInput>;
  label?: InputMaybe<StringFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PortalResourceFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PortalResourceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PortalResourceFiltersInput>>>;
  productType?: InputMaybe<ProductTypeFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  region?: InputMaybe<RegionFiltersInput>;
  sections?: InputMaybe<PortalResourceSectionFiltersInput>;
  subheading?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type PortalResourceInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  eap?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  isSaasOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  productType?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  region?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  subheading?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type PortalResourceRelationResponseCollection = {
  __typename?: "PortalResourceRelationResponseCollection";
  data: Array<PortalResourceEntity>;
};

export type PortalResourceSection = {
  __typename?: "PortalResourceSection";
  carriers?: Maybe<CarrierRelationResponseCollection>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<PortalResourceSectionRelationResponseCollection>;
  name?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PortalResourceSectionCarriersArgs = {
  filters?: InputMaybe<CarrierFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceSectionLocalizationsArgs = {
  filters?: InputMaybe<PortalResourceSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PortalResourceSectionEntity = {
  __typename?: "PortalResourceSectionEntity";
  attributes?: Maybe<PortalResourceSection>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type PortalResourceSectionEntityResponse = {
  __typename?: "PortalResourceSectionEntityResponse";
  data?: Maybe<PortalResourceSectionEntity>;
};

export type PortalResourceSectionEntityResponseCollection = {
  __typename?: "PortalResourceSectionEntityResponseCollection";
  data: Array<PortalResourceSectionEntity>;
  meta: ResponseCollectionMeta;
};

export type PortalResourceSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PortalResourceSectionFiltersInput>>>;
  carriers?: InputMaybe<CarrierFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PortalResourceSectionFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PortalResourceSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PortalResourceSectionFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PortalResourceSectionInput = {
  carriers?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type PortalResourceSectionRelationResponseCollection = {
  __typename?: "PortalResourceSectionRelationResponseCollection";
  data: Array<PortalResourceSectionEntity>;
};

export type ProductType = {
  __typename?: "ProductType";
  codes?: Maybe<Scalars["JSON"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<ProductTypeRelationResponseCollection>;
  name: Scalars["String"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ProductTypeLocalizationsArgs = {
  filters?: InputMaybe<ProductTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ProductTypeEntity = {
  __typename?: "ProductTypeEntity";
  attributes?: Maybe<ProductType>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type ProductTypeEntityResponse = {
  __typename?: "ProductTypeEntityResponse";
  data?: Maybe<ProductTypeEntity>;
};

export type ProductTypeEntityResponseCollection = {
  __typename?: "ProductTypeEntityResponseCollection";
  data: Array<ProductTypeEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductTypeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductTypeFiltersInput>>>;
  codes?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ProductTypeFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProductTypeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductTypeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductTypeInput = {
  codes?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ProductTypeRelationResponseCollection = {
  __typename?: "ProductTypeRelationResponseCollection";
  data: Array<ProductTypeEntity>;
};

export enum PublicationState {
  Live = "LIVE",
  Preview = "PREVIEW",
}

export type Query = {
  __typename?: "Query";
  auditLog?: Maybe<AuditLogEntityResponse>;
  auditLogs?: Maybe<AuditLogEntityResponseCollection>;
  author?: Maybe<AuthorEntityResponse>;
  authors?: Maybe<AuthorEntityResponseCollection>;
  blogPost?: Maybe<BlogPostEntityResponse>;
  blogPostTag?: Maybe<BlogPostTagEntityResponse>;
  blogPostTags?: Maybe<BlogPostTagEntityResponseCollection>;
  blogPosts?: Maybe<BlogPostEntityResponseCollection>;
  carrier?: Maybe<CarrierEntityResponse>;
  carriers?: Maybe<CarrierEntityResponseCollection>;
  caseStudies?: Maybe<CaseStudyEntityResponseCollection>;
  caseStudy?: Maybe<CaseStudyEntityResponse>;
  categoryBlogPost?: Maybe<CategoryBlogPostEntityResponse>;
  categoryBlogPosts?: Maybe<CategoryBlogPostEntityResponseCollection>;
  categoryCaseStudies?: Maybe<CategoryCaseStudyEntityResponseCollection>;
  categoryCaseStudy?: Maybe<CategoryCaseStudyEntityResponse>;
  categoryEvent?: Maybe<CategoryEventEntityResponse>;
  categoryEvents?: Maybe<CategoryEventEntityResponseCollection>;
  categoryNewsAndEvent?: Maybe<CategoryNewsAndEventEntityResponse>;
  categoryNewsAndEvents?: Maybe<CategoryNewsAndEventEntityResponseCollection>;
  categoryPage?: Maybe<CategoryPageEntityResponse>;
  categoryPages?: Maybe<CategoryPageEntityResponseCollection>;
  companyType?: Maybe<CompanyTypeEntityResponse>;
  companyTypes?: Maybe<CompanyTypeEntityResponseCollection>;
  emailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  emailDesignerEmailTemplates?: Maybe<EmailDesignerEmailTemplateEntityResponseCollection>;
  event?: Maybe<EventEntityResponse>;
  events?: Maybe<EventEntityResponseCollection>;
  filterAudience?: Maybe<FilterAudienceEntityResponse>;
  filterAudiences?: Maybe<FilterAudienceEntityResponseCollection>;
  filterBusinessGoal?: Maybe<FilterBusinessGoalEntityResponse>;
  filterBusinessGoals?: Maybe<FilterBusinessGoalEntityResponseCollection>;
  filterContentType?: Maybe<FilterContentTypeEntityResponse>;
  filterContentTypes?: Maybe<FilterContentTypeEntityResponseCollection>;
  filterDealStage?: Maybe<FilterDealStageEntityResponse>;
  filterDealStages?: Maybe<FilterDealStageEntityResponseCollection>;
  filterEmployee?: Maybe<FilterEmployeeEntityResponse>;
  filterEmployees?: Maybe<FilterEmployeeEntityResponseCollection>;
  filterIndustrySector?: Maybe<FilterIndustrySectorEntityResponse>;
  filterIndustrySectors?: Maybe<FilterIndustrySectorEntityResponseCollection>;
  filterProduct?: Maybe<FilterProductEntityResponse>;
  filterProducts?: Maybe<FilterProductEntityResponseCollection>;
  filterProvider?: Maybe<FilterProviderEntityResponse>;
  filterProviders?: Maybe<FilterProviderEntityResponseCollection>;
  filterRegion?: Maybe<FilterRegionEntityResponse>;
  filterRegions?: Maybe<FilterRegionEntityResponseCollection>;
  filterWorkingArrangement?: Maybe<FilterWorkingArrangementEntityResponse>;
  filterWorkingArrangements?: Maybe<FilterWorkingArrangementEntityResponseCollection>;
  getAllTags?: Maybe<Array<Maybe<Tag>>>;
  getBlogPost?: Maybe<BlogPostEntity>;
  getCaseStudyPost?: Maybe<CaseStudyEntity>;
  getFeaturedResources?: Maybe<Array<Maybe<Resource>>>;
  getGenericProps?: Maybe<GenericProps>;
  getLatestCaseStudy?: Maybe<MiniResource>;
  getNewsAndEventPost?: Maybe<NewsAndEventEntity>;
  getRecommendedResources?: Maybe<Array<Maybe<MiniResource>>>;
  getResourcesHomepage?: Maybe<ResourcesHomepage>;
  gettingStartedSection?: Maybe<GettingStartedSectionEntityResponse>;
  gettingStartedSections?: Maybe<GettingStartedSectionEntityResponseCollection>;
  gettingStartedTask?: Maybe<GettingStartedTaskEntityResponse>;
  gettingStartedTasks?: Maybe<GettingStartedTaskEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  legalPage?: Maybe<LegalPageEntityResponse>;
  legalPages?: Maybe<LegalPageEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  newsAndEvent?: Maybe<NewsAndEventEntityResponse>;
  newsAndEvents?: Maybe<NewsAndEventEntityResponseCollection>;
  page?: Maybe<PageEntityResponse>;
  pages?: Maybe<PageEntityResponseCollection>;
  portalArticle?: Maybe<PortalArticleEntityResponse>;
  portalArticles?: Maybe<PortalArticleEntityResponseCollection>;
  portalResource?: Maybe<PortalResourceEntityResponse>;
  portalResourceEapOption?: Maybe<PortalResourceEapOptionEntityResponse>;
  portalResourceEapOptions?: Maybe<PortalResourceEapOptionEntityResponseCollection>;
  portalResourceSection?: Maybe<PortalResourceSectionEntityResponse>;
  portalResourceSections?: Maybe<PortalResourceSectionEntityResponseCollection>;
  portalResources?: Maybe<PortalResourceEntityResponseCollection>;
  productType?: Maybe<ProductTypeEntityResponse>;
  productTypes?: Maybe<ProductTypeEntityResponseCollection>;
  region?: Maybe<RegionEntityResponse>;
  regions?: Maybe<RegionEntityResponseCollection>;
  supportHubResource?: Maybe<SupportHubResourceEntityResponse>;
  supportHubResources?: Maybe<SupportHubResourceEntityResponseCollection>;
  supportHubSection?: Maybe<SupportHubSectionEntityResponse>;
  supportHubSections?: Maybe<SupportHubSectionEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  video?: Maybe<VideoEntityResponse>;
  videos?: Maybe<VideoEntityResponseCollection>;
};

export type QueryAuditLogArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryAuditLogsArgs = {
  filters?: InputMaybe<AuditLogFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryAuthorArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryAuthorsArgs = {
  filters?: InputMaybe<AuthorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryBlogPostArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryBlogPostTagArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryBlogPostTagsArgs = {
  filters?: InputMaybe<BlogPostTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryBlogPostsArgs = {
  filters?: InputMaybe<BlogPostFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCarrierArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryCarriersArgs = {
  filters?: InputMaybe<CarrierFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCaseStudiesArgs = {
  filters?: InputMaybe<CaseStudyFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCaseStudyArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryCategoryBlogPostArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCategoryBlogPostsArgs = {
  filters?: InputMaybe<CategoryBlogPostFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCategoryCaseStudiesArgs = {
  filters?: InputMaybe<CategoryCaseStudyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCategoryCaseStudyArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCategoryEventArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCategoryEventsArgs = {
  filters?: InputMaybe<CategoryEventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCategoryNewsAndEventArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCategoryNewsAndEventsArgs = {
  filters?: InputMaybe<CategoryNewsAndEventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCategoryPageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCategoryPagesArgs = {
  filters?: InputMaybe<CategoryPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryCompanyTypeArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryCompanyTypesArgs = {
  filters?: InputMaybe<CompanyTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryEmailDesignerEmailTemplateArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryEmailDesignerEmailTemplatesArgs = {
  filters?: InputMaybe<EmailDesignerEmailTemplateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryEventArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryEventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterAudienceArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterAudiencesArgs = {
  filters?: InputMaybe<FilterAudienceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterBusinessGoalArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterBusinessGoalsArgs = {
  filters?: InputMaybe<FilterBusinessGoalFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterContentTypeArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterContentTypesArgs = {
  filters?: InputMaybe<FilterContentTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterDealStageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterDealStagesArgs = {
  filters?: InputMaybe<FilterDealStageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterEmployeeArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterEmployeesArgs = {
  filters?: InputMaybe<FilterEmployeeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterIndustrySectorArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterIndustrySectorsArgs = {
  filters?: InputMaybe<FilterIndustrySectorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterProductArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterProductsArgs = {
  filters?: InputMaybe<FilterProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterProviderArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterProvidersArgs = {
  filters?: InputMaybe<FilterProviderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterRegionArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterRegionsArgs = {
  filters?: InputMaybe<FilterRegionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryFilterWorkingArrangementArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryFilterWorkingArrangementsArgs = {
  filters?: InputMaybe<FilterWorkingArrangementFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGetBlogPostArgs = {
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  slug: Scalars["String"]["input"];
};

export type QueryGetCaseStudyPostArgs = {
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  slug: Scalars["String"]["input"];
};

export type QueryGetFeaturedResourcesArgs = {
  locale: Scalars["I18NLocaleCode"]["input"];
  tag?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetGenericPropsArgs = {
  locale: Scalars["I18NLocaleCode"]["input"];
  options?: InputMaybe<GenericPropOptions>;
};

export type QueryGetNewsAndEventPostArgs = {
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  slug: Scalars["String"]["input"];
};

export type QueryGetRecommendedResourcesArgs = {
  locale: Scalars["I18NLocaleCode"]["input"];
  resourceType: ResourceType;
  slugToExclude?: InputMaybe<Scalars["String"]["input"]>;
  tag?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetResourcesHomepageArgs = {
  locale: Scalars["I18NLocaleCode"]["input"];
  tag?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGettingStartedSectionArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryGettingStartedSectionsArgs = {
  filters?: InputMaybe<GettingStartedSectionFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryGettingStartedTaskArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryGettingStartedTasksArgs = {
  filters?: InputMaybe<GettingStartedTaskFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryLegalPageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryLegalPagesArgs = {
  filters?: InputMaybe<LegalPageFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryNewsAndEventArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryNewsAndEventsArgs = {
  filters?: InputMaybe<NewsAndEventFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryPageArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryPortalArticleArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryPortalArticlesArgs = {
  filters?: InputMaybe<PortalArticleFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryPortalResourceArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryPortalResourceEapOptionArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPortalResourceEapOptionsArgs = {
  filters?: InputMaybe<PortalResourceEapOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryPortalResourceSectionArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryPortalResourceSectionsArgs = {
  filters?: InputMaybe<PortalResourceSectionFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryPortalResourcesArgs = {
  filters?: InputMaybe<PortalResourceFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryProductTypeArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryProductTypesArgs = {
  filters?: InputMaybe<ProductTypeFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryRegionArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryRegionsArgs = {
  filters?: InputMaybe<RegionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QuerySupportHubResourceArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QuerySupportHubResourcesArgs = {
  filters?: InputMaybe<SupportHubResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QuerySupportHubSectionArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QuerySupportHubSectionsArgs = {
  filters?: InputMaybe<SupportHubSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryVideoArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
};

export type QueryVideosArgs = {
  filters?: InputMaybe<VideoFiltersInput>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Region = {
  __typename?: "Region";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  region: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type RegionEntity = {
  __typename?: "RegionEntity";
  attributes?: Maybe<Region>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type RegionEntityResponse = {
  __typename?: "RegionEntityResponse";
  data?: Maybe<RegionEntity>;
};

export type RegionEntityResponseCollection = {
  __typename?: "RegionEntityResponseCollection";
  data: Array<RegionEntity>;
  meta: ResponseCollectionMeta;
};

export type RegionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<RegionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<RegionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<RegionFiltersInput>>>;
  region?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RegionInput = {
  region?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegionRelationResponseCollection = {
  __typename?: "RegionRelationResponseCollection";
  data: Array<RegionEntity>;
};

export type Resource = {
  __typename?: "Resource";
  altText?: Maybe<Scalars["String"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  excerpt?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  featuredImageFormats?: Maybe<StrapiImageFormats>;
  id?: Maybe<Scalars["ID"]["output"]>;
  minutes?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  tag?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum ResourceType {
  Blog = "blog",
  CaseStudy = "caseStudy",
  NewsAndEvents = "newsAndEvents",
}

export type ResourcesHomepage = {
  __typename?: "ResourcesHomepage";
  blogs?: Maybe<Array<Maybe<Resource>>>;
  caseStudies?: Maybe<Array<Maybe<Resource>>>;
  featuredResources?: Maybe<Array<Maybe<Resource>>>;
  newsEvents?: Maybe<Array<Maybe<Resource>>>;
};

export type ResponseCollectionMeta = {
  __typename?: "ResponseCollectionMeta";
  pagination: Pagination;
};

export type StrapiImageFormat = {
  __typename?: "StrapiImageFormat";
  ext: Scalars["String"]["output"];
  hash: Scalars["String"]["output"];
  height?: Maybe<Scalars["Float"]["output"]>;
  mime: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  path?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Float"]["output"]>;
  url: Scalars["String"]["output"];
  width?: Maybe<Scalars["Float"]["output"]>;
};

export type StrapiImageFormats = {
  __typename?: "StrapiImageFormats";
  large?: Maybe<StrapiImageFormat>;
  medium?: Maybe<StrapiImageFormat>;
  small?: Maybe<StrapiImageFormat>;
  thumbnail?: Maybe<StrapiImageFormat>;
  xlarge?: Maybe<StrapiImageFormat>;
  xsmall?: Maybe<StrapiImageFormat>;
  xxlarge?: Maybe<StrapiImageFormat>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  containsi?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  eq?: InputMaybe<Scalars["String"]["input"]>;
  eqi?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  ne?: InputMaybe<Scalars["String"]["input"]>;
  nei?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars["String"]["input"]>;
  notContainsi?: InputMaybe<Scalars["String"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  null?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupportHubResource = {
  __typename?: "SupportHubResource";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  filterAudiences?: Maybe<FilterAudienceRelationResponseCollection>;
  filterBusinessGoals?: Maybe<FilterBusinessGoalRelationResponseCollection>;
  filterContentTypes?: Maybe<FilterContentTypeRelationResponseCollection>;
  filterDealStages?: Maybe<FilterDealStageRelationResponseCollection>;
  filterEmployees?: Maybe<FilterEmployeeRelationResponseCollection>;
  filterIndustrySectors?: Maybe<FilterIndustrySectorRelationResponseCollection>;
  filterProviders?: Maybe<FilterProviderRelationResponseCollection>;
  filterRegions?: Maybe<FilterRegionRelationResponseCollection>;
  filterWorkingArrangements?: Maybe<FilterWorkingArrangementRelationResponseCollection>;
  name: Scalars["String"]["output"];
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  resourceType: Array<Maybe<SupportHubResourceResourceTypeDynamicZone>>;
  supportHubSection?: Maybe<SupportHubSectionRelationResponseCollection>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SupportHubResourceFilterAudiencesArgs = {
  filters?: InputMaybe<FilterAudienceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterBusinessGoalsArgs = {
  filters?: InputMaybe<FilterBusinessGoalFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterContentTypesArgs = {
  filters?: InputMaybe<FilterContentTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterDealStagesArgs = {
  filters?: InputMaybe<FilterDealStageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterEmployeesArgs = {
  filters?: InputMaybe<FilterEmployeeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterIndustrySectorsArgs = {
  filters?: InputMaybe<FilterIndustrySectorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterProvidersArgs = {
  filters?: InputMaybe<FilterProviderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterRegionsArgs = {
  filters?: InputMaybe<FilterRegionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceFilterWorkingArrangementsArgs = {
  filters?: InputMaybe<FilterWorkingArrangementFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceSupportHubSectionArgs = {
  filters?: InputMaybe<SupportHubSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type SupportHubResourceEntity = {
  __typename?: "SupportHubResourceEntity";
  attributes?: Maybe<SupportHubResource>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type SupportHubResourceEntityResponse = {
  __typename?: "SupportHubResourceEntityResponse";
  data?: Maybe<SupportHubResourceEntity>;
};

export type SupportHubResourceEntityResponseCollection = {
  __typename?: "SupportHubResourceEntityResponseCollection";
  data: Array<SupportHubResourceEntity>;
  meta: ResponseCollectionMeta;
};

export type SupportHubResourceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupportHubResourceFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  filterAudiences?: InputMaybe<FilterAudienceFiltersInput>;
  filterBusinessGoals?: InputMaybe<FilterBusinessGoalFiltersInput>;
  filterContentTypes?: InputMaybe<FilterContentTypeFiltersInput>;
  filterDealStages?: InputMaybe<FilterDealStageFiltersInput>;
  filterEmployees?: InputMaybe<FilterEmployeeFiltersInput>;
  filterIndustrySectors?: InputMaybe<FilterIndustrySectorFiltersInput>;
  filterProviders?: InputMaybe<FilterProviderFiltersInput>;
  filterRegions?: InputMaybe<FilterRegionFiltersInput>;
  filterWorkingArrangements?: InputMaybe<FilterWorkingArrangementFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SupportHubResourceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupportHubResourceFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  supportHubSection?: InputMaybe<SupportHubSectionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupportHubResourceInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  filterAudiences?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterBusinessGoals?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterContentTypes?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterDealStages?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterEmployees?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterIndustrySectors?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterProviders?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterRegions?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  filterWorkingArrangements?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  resourceType?: InputMaybe<
    Array<Scalars["SupportHubResourceResourceTypeDynamicZoneInput"]["input"]>
  >;
  supportHubSection?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type SupportHubResourceResourceTypeDynamicZone =
  | ComponentSupportHubResourceLink
  | ComponentSupportHubResourceVimeoVideo
  | Error;

export type SupportHubSection = {
  __typename?: "SupportHubSection";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  section: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SupportHubSectionEntity = {
  __typename?: "SupportHubSectionEntity";
  attributes?: Maybe<SupportHubSection>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type SupportHubSectionEntityResponse = {
  __typename?: "SupportHubSectionEntityResponse";
  data?: Maybe<SupportHubSectionEntity>;
};

export type SupportHubSectionEntityResponseCollection = {
  __typename?: "SupportHubSectionEntityResponseCollection";
  data: Array<SupportHubSectionEntity>;
  meta: ResponseCollectionMeta;
};

export type SupportHubSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupportHubSectionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SupportHubSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupportHubSectionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  section?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupportHubSectionInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  section?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupportHubSectionRelationResponseCollection = {
  __typename?: "SupportHubSectionRelationResponseCollection";
  data: Array<SupportHubSectionEntity>;
};

export type Tag = {
  __typename?: "Tag";
  description?: Maybe<Scalars["String"]["output"]>;
  iconAlt?: Maybe<Scalars["String"]["output"]>;
  iconSrc?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  uid?: Maybe<Scalars["String"]["output"]>;
};

export type UploadFile = {
  __typename?: "UploadFile";
  alternativeText?: Maybe<Scalars["String"]["output"]>;
  caption?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  ext?: Maybe<Scalars["String"]["output"]>;
  formats?: Maybe<Scalars["JSON"]["output"]>;
  hash: Scalars["String"]["output"];
  height?: Maybe<Scalars["Int"]["output"]>;
  mime: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  previewUrl?: Maybe<Scalars["String"]["output"]>;
  provider: Scalars["String"]["output"];
  provider_metadata?: Maybe<Scalars["JSON"]["output"]>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars["Float"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  url: Scalars["String"]["output"];
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type UploadFileEntity = {
  __typename?: "UploadFileEntity";
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type UploadFileEntityResponse = {
  __typename?: "UploadFileEntityResponse";
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: "UploadFileEntityResponseCollection";
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars["String"]["input"]>;
  caption?: InputMaybe<Scalars["String"]["input"]>;
  ext?: InputMaybe<Scalars["String"]["input"]>;
  folder?: InputMaybe<Scalars["ID"]["input"]>;
  folderPath?: InputMaybe<Scalars["String"]["input"]>;
  formats?: InputMaybe<Scalars["JSON"]["input"]>;
  hash?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  mime?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  previewUrl?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<Scalars["String"]["input"]>;
  provider_metadata?: InputMaybe<Scalars["JSON"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: "UploadFileRelationResponseCollection";
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: "UploadFolder";
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars["String"]["output"];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars["String"]["output"];
  pathId: Scalars["Int"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UploadFolderEntity = {
  __typename?: "UploadFolderEntity";
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type UploadFolderEntityResponse = {
  __typename?: "UploadFolderEntityResponse";
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: "UploadFolderEntityResponseCollection";
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parent?: InputMaybe<Scalars["ID"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  pathId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: "UploadFolderRelationResponseCollection";
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: "UsersPermissionsCreateRolePayload";
  ok: Scalars["Boolean"]["output"];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: "UsersPermissionsDeleteRolePayload";
  ok: Scalars["Boolean"]["output"];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  provider?: Scalars["String"]["input"];
};

export type UsersPermissionsLoginPayload = {
  __typename?: "UsersPermissionsLoginPayload";
  jwt?: Maybe<Scalars["String"]["output"]>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: "UsersPermissionsMe";
  blocked?: Maybe<Scalars["Boolean"]["output"]>;
  confirmed?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars["String"]["output"];
};

export type UsersPermissionsMeRole = {
  __typename?: "UsersPermissionsMeRole";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: "UsersPermissionsPasswordPayload";
  ok: Scalars["Boolean"]["output"];
};

export type UsersPermissionsPermission = {
  __typename?: "UsersPermissionsPermission";
  action: Scalars["String"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: "UsersPermissionsPermissionEntity";
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: "UsersPermissionsPermissionRelationResponseCollection";
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  username: Scalars["String"]["input"];
};

export type UsersPermissionsRole = {
  __typename?: "UsersPermissionsRole";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: "UsersPermissionsRoleEntity";
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: "UsersPermissionsRoleEntityResponse";
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: "UsersPermissionsRoleEntityResponseCollection";
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: "UsersPermissionsUpdateRolePayload";
  ok: Scalars["Boolean"]["output"];
};

export type UsersPermissionsUser = {
  __typename?: "UsersPermissionsUser";
  blocked?: Maybe<Scalars["Boolean"]["output"]>;
  confirmed?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  provider?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  username: Scalars["String"]["output"];
};

export type UsersPermissionsUserEntity = {
  __typename?: "UsersPermissionsUserEntity";
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: "UsersPermissionsUserEntityResponse";
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: "UsersPermissionsUserEntityResponseCollection";
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars["Boolean"]["input"]>;
  confirmationToken?: InputMaybe<Scalars["String"]["input"]>;
  confirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<Scalars["String"]["input"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["ID"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: "UsersPermissionsUserRelationResponseCollection";
  data: Array<UsersPermissionsUserEntity>;
};

export type Video = {
  __typename?: "Video";
  category?: Maybe<CategoryBlogPostEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  excerpt?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<UploadFileEntityResponse>;
  featuredOrder?: Maybe<Scalars["Int"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  localizations?: Maybe<VideoRelationResponseCollection>;
  publishDate?: Maybe<Scalars["DateTime"]["output"]>;
  publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  seconds?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type VideoLocalizationsArgs = {
  filters?: InputMaybe<VideoFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type VideoEntity = {
  __typename?: "VideoEntity";
  attributes?: Maybe<Video>;
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type VideoEntityResponse = {
  __typename?: "VideoEntityResponse";
  data?: Maybe<VideoEntity>;
};

export type VideoEntityResponseCollection = {
  __typename?: "VideoEntityResponseCollection";
  data: Array<VideoEntity>;
  meta: ResponseCollectionMeta;
};

export type VideoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<VideoFiltersInput>>>;
  category?: InputMaybe<CategoryBlogPostFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  excerpt?: InputMaybe<StringFilterInput>;
  featuredOrder?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isFeatured?: InputMaybe<BooleanFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<VideoFiltersInput>;
  not?: InputMaybe<VideoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<VideoFiltersInput>>>;
  publishDate?: InputMaybe<DateTimeFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seconds?: InputMaybe<IntFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type VideoInput = {
  category?: InputMaybe<Scalars["ID"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  featuredImage?: InputMaybe<Scalars["ID"]["input"]>;
  featuredOrder?: InputMaybe<Scalars["Int"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  publishDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  seconds?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type VideoRelationResponseCollection = {
  __typename?: "VideoRelationResponseCollection";
  data: Array<VideoEntity>;
};

export type BlogDataQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type BlogDataQuery = {
  __typename?: "Query";
  blogPosts?: {
    __typename?: "BlogPostEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostEntity";
      id?: string | null;
      attributes?: {
        __typename?: "BlogPost";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              formats?: any | null;
              caption?: string | null;
            } | null;
          } | null;
        };
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryBlogPostEntityResponse";
          data?: {
            __typename?: "CategoryBlogPostEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryBlogPost";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            attributes?: { __typename?: "BlogPostTag"; name: string; uid: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type BlogSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type BlogSlugsQuery = {
  __typename?: "Query";
  blogPosts?: {
    __typename?: "BlogPostEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostEntity";
      id?: string | null;
      attributes?: { __typename?: "BlogPost"; slug: string; locale?: string | null } | null;
    }>;
  } | null;
};

export type CaseStudyCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CaseStudyCategoriesQuery = {
  __typename?: "Query";
  categoryCaseStudies?: {
    __typename?: "CategoryCaseStudyEntityResponseCollection";
    data: Array<{
      __typename?: "CategoryCaseStudyEntity";
      attributes?: {
        __typename?: "CategoryCaseStudy";
        name?: string | null;
        slug: string;
        description?: string | null;
      } | null;
    }>;
  } | null;
};

export type CaseStudyDataQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
}>;

export type CaseStudyDataQuery = {
  __typename?: "Query";
  caseStudies?: {
    __typename?: "CaseStudyEntityResponseCollection";
    data: Array<{
      __typename?: "CaseStudyEntity";
      id?: string | null;
      attributes?: {
        __typename?: "CaseStudy";
        title?: string | null;
        excerpt?: string | null;
        slug: string;
        post?: string | null;
        publishDate?: any | null;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryCaseStudyEntityResponse";
          data?: {
            __typename?: "CategoryCaseStudyEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryCaseStudy";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            id?: string | null;
            attributes?: { __typename?: "BlogPostTag"; uid: string; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CaseStudySlugsQueryVariables = Exact<{ [key: string]: never }>;

export type CaseStudySlugsQuery = {
  __typename?: "Query";
  caseStudies?: {
    __typename?: "CaseStudyEntityResponseCollection";
    data: Array<{
      __typename?: "CaseStudyEntity";
      id?: string | null;
      attributes?: { __typename?: "CaseStudy"; slug: string; locale?: string | null } | null;
    }>;
  } | null;
};

export type AllBlogPostsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<BlogPostFiltersInput>;
}>;

export type AllBlogPostsQuery = {
  __typename?: "Query";
  blogPosts?: {
    __typename?: "BlogPostEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostEntity";
      id?: string | null;
      attributes?: {
        __typename?: "BlogPost";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        isFeatured?: boolean | null;
        featuredOrder?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              formats?: any | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        };
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryBlogPostEntityResponse";
          data?: {
            __typename?: "CategoryBlogPostEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryBlogPost";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            id?: string | null;
            attributes?: { __typename?: "BlogPostTag"; name: string; uid: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllCaseStudiesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<CaseStudyFiltersInput>;
}>;

export type AllCaseStudiesQuery = {
  __typename?: "Query";
  caseStudies?: {
    __typename?: "CaseStudyEntityResponseCollection";
    data: Array<{
      __typename?: "CaseStudyEntity";
      id?: string | null;
      attributes?: {
        __typename?: "CaseStudy";
        title?: string | null;
        excerpt?: string | null;
        slug: string;
        post?: string | null;
        publishDate?: any | null;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        isFeatured?: boolean | null;
        featuredOrder?: number | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              formats?: any | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryCaseStudyEntityResponse";
          data?: {
            __typename?: "CategoryCaseStudyEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryCaseStudy";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            id?: string | null;
            attributes?: { __typename?: "BlogPostTag"; name: string; uid: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllLegalPagesQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
}>;

export type AllLegalPagesQuery = {
  __typename?: "Query";
  legalPages?: {
    __typename?: "LegalPageEntityResponseCollection";
    data: Array<{
      __typename?: "LegalPageEntity";
      id?: string | null;
      attributes?: { __typename?: "LegalPage"; slug: string; title: string } | null;
    }>;
  } | null;
};

export type AllNewsEventsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<NewsAndEventFiltersInput>;
}>;

export type AllNewsEventsQuery = {
  __typename?: "Query";
  newsAndEvents?: {
    __typename?: "NewsAndEventEntityResponseCollection";
    data: Array<{
      __typename?: "NewsAndEventEntity";
      id?: string | null;
      attributes?: {
        __typename?: "NewsAndEvent";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        isFeatured?: boolean | null;
        featuredOrder?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              formats?: any | null;
              alternativeText?: string | null;
            } | null;
          } | null;
        };
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            id?: string | null;
            attributes?: { __typename?: "BlogPostTag"; name: string; uid: string } | null;
          }>;
        } | null;
        category?: {
          __typename?: "CategoryNewsAndEventEntityResponse";
          data?: {
            __typename?: "CategoryNewsAndEventEntity";
            id?: string | null;
            attributes?: { __typename?: "CategoryNewsAndEvent"; name: string; slug: string } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllPagesQueryVariables = Exact<{
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type AllPagesQuery = {
  __typename?: "Query";
  pages?: {
    __typename?: "PageEntityResponseCollection";
    data: Array<{
      __typename?: "PageEntity";
      id?: string | null;
      attributes?: { __typename?: "Page"; slug: string; title: string } | null;
    }>;
  } | null;
};

export type GetBlogPostQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
  locale: Scalars["I18NLocaleCode"]["input"];
}>;

export type GetBlogPostQuery = {
  __typename?: "Query";
  getBlogPost?: {
    __typename?: "BlogPostEntity";
    id?: string | null;
    attributes?: {
      __typename?: "BlogPost";
      title: string;
      excerpt: string;
      slug: string;
      post: string;
      publishDate: any;
      metaTitle?: string | null;
      metaDescription?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      publishedAt?: any | null;
      tags?: {
        __typename?: "BlogPostTagRelationResponseCollection";
        data: Array<{
          __typename?: "BlogPostTagEntity";
          attributes?: {
            __typename?: "BlogPostTag";
            name: string;
            uid: string;
            description?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            icon?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  alternativeText?: string | null;
                  caption?: string | null;
                  width?: number | null;
                  height?: number | null;
                  formats?: any | null;
                  hash: string;
                  ext?: string | null;
                  mime: string;
                } | null;
              } | null;
            } | null;
          } | null;
        }>;
      } | null;
      category?: {
        __typename?: "CategoryBlogPostEntityResponse";
        data?: {
          __typename?: "CategoryBlogPostEntity";
          attributes?: {
            __typename?: "CategoryBlogPost";
            name?: string | null;
            slug: string;
            description?: string | null;
          } | null;
        } | null;
      } | null;
      author?: {
        __typename?: "AuthorEntityResponse";
        data?: {
          __typename?: "AuthorEntity";
          attributes?: {
            __typename?: "Author";
            name?: string | null;
            bio?: string | null;
            email?: string | null;
            twitterHandle?: string | null;
            slug?: string | null;
            location?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            publishedAt?: any | null;
          } | null;
        } | null;
      } | null;
      featuredImage: {
        __typename?: "UploadFileEntityResponse";
        data?: {
          __typename?: "UploadFileEntity";
          attributes?: {
            __typename?: "UploadFile";
            formats?: any | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      };
    } | null;
  } | null;
};

export type GetBlogTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBlogTagsQuery = {
  __typename?: "Query";
  blogPostTags?: {
    __typename?: "BlogPostTagEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostTagEntity";
      id?: string | null;
      attributes?: {
        __typename?: "BlogPostTag";
        name: string;
        uid: string;
        description?: string | null;
        icon?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            attributes?: {
              __typename?: "UploadFile";
              url: string;
              alternativeText?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetCaseStudyPostQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
  locale: Scalars["I18NLocaleCode"]["input"];
}>;

export type GetCaseStudyPostQuery = {
  __typename?: "Query";
  getCaseStudyPost?: {
    __typename?: "CaseStudyEntity";
    id?: string | null;
    attributes?: {
      __typename?: "CaseStudy";
      title?: string | null;
      excerpt?: string | null;
      slug: string;
      post?: string | null;
      publishDate?: any | null;
      metaTitle?: string | null;
      metaDescription?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      publishedAt?: any | null;
      tags?: {
        __typename?: "BlogPostTagRelationResponseCollection";
        data: Array<{
          __typename?: "BlogPostTagEntity";
          attributes?: {
            __typename?: "BlogPostTag";
            name: string;
            uid: string;
            description?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            icon?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  alternativeText?: string | null;
                  caption?: string | null;
                  width?: number | null;
                  height?: number | null;
                  formats?: any | null;
                  hash: string;
                  ext?: string | null;
                  mime: string;
                } | null;
              } | null;
            } | null;
          } | null;
        }>;
      } | null;
      category?: {
        __typename?: "CategoryCaseStudyEntityResponse";
        data?: {
          __typename?: "CategoryCaseStudyEntity";
          attributes?: {
            __typename?: "CategoryCaseStudy";
            name?: string | null;
            slug: string;
            description?: string | null;
          } | null;
        } | null;
      } | null;
      author?: {
        __typename?: "AuthorEntityResponse";
        data?: {
          __typename?: "AuthorEntity";
          attributes?: {
            __typename?: "Author";
            name?: string | null;
            bio?: string | null;
            email?: string | null;
            twitterHandle?: string | null;
            slug?: string | null;
            location?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            publishedAt?: any | null;
          } | null;
        } | null;
      } | null;
      featuredImage?: {
        __typename?: "UploadFileEntityResponse";
        data?: {
          __typename?: "UploadFileEntity";
          attributes?: {
            __typename?: "UploadFile";
            formats?: any | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetFeaturedResourcesQueryVariables = Exact<{
  locale: Scalars["I18NLocaleCode"]["input"];
  tag?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetFeaturedResourcesQuery = {
  __typename?: "Query";
  getFeaturedResources?: Array<{
    __typename?: "Resource";
    id?: string | null;
    title?: string | null;
    excerpt?: string | null;
    featuredImage?: string | null;
    tag?: string | null;
    slug?: string | null;
    contentType?: string | null;
    minutes?: string | null;
    featuredImageFormats?: {
      __typename?: "StrapiImageFormats";
      thumbnail?: { __typename?: "StrapiImageFormat"; url: string } | null;
      xsmall?: { __typename?: "StrapiImageFormat"; url: string } | null;
      small?: { __typename?: "StrapiImageFormat"; url: string } | null;
      large?: { __typename?: "StrapiImageFormat"; url: string } | null;
      medium?: { __typename?: "StrapiImageFormat"; url: string } | null;
      xlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
      xxlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
    } | null;
  } | null> | null;
};

export type GetGenericPropsQueryVariables = Exact<{
  locale: Scalars["I18NLocaleCode"]["input"];
  options: GenericPropOptions;
}>;

export type GetGenericPropsQuery = {
  __typename?: "Query";
  getGenericProps?: {
    __typename?: "GenericProps";
    latestCaseStudy?: {
      __typename?: "MiniResource";
      tag?: string | null;
      img?: string | null;
      alt?: string | null;
      title?: string | null;
      readTime?: string | null;
      slug?: string | null;
    } | null;
    keyResources?: Array<{
      __typename?: "MiniResource";
      tag?: string | null;
      img?: string | null;
      alt?: string | null;
      title?: string | null;
      readTime?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null;
};

export type LegalPageDataQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LegalPageDataQuery = {
  __typename?: "Query";
  legalPages?: {
    __typename?: "LegalPageEntityResponseCollection";
    data: Array<{
      __typename?: "LegalPageEntity";
      id?: string | null;
      attributes?: {
        __typename?: "LegalPage";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetNewsAndEventPostQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
  locale: Scalars["I18NLocaleCode"]["input"];
}>;

export type GetNewsAndEventPostQuery = {
  __typename?: "Query";
  getNewsAndEventPost?: {
    __typename?: "NewsAndEventEntity";
    id?: string | null;
    attributes?: {
      __typename?: "NewsAndEvent";
      title: string;
      excerpt: string;
      slug: string;
      post: string;
      publishDate: any;
      metaTitle?: string | null;
      metaDescription?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      publishedAt?: any | null;
      tags?: {
        __typename?: "BlogPostTagRelationResponseCollection";
        data: Array<{
          __typename?: "BlogPostTagEntity";
          attributes?: {
            __typename?: "BlogPostTag";
            name: string;
            uid: string;
            description?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            icon?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  alternativeText?: string | null;
                  caption?: string | null;
                  width?: number | null;
                  height?: number | null;
                  formats?: any | null;
                  hash: string;
                  ext?: string | null;
                  mime: string;
                } | null;
              } | null;
            } | null;
          } | null;
        }>;
      } | null;
      category?: {
        __typename?: "CategoryNewsAndEventEntityResponse";
        data?: {
          __typename?: "CategoryNewsAndEventEntity";
          attributes?: {
            __typename?: "CategoryNewsAndEvent";
            name: string;
            slug: string;
            description?: string | null;
          } | null;
        } | null;
      } | null;
      author?: {
        __typename?: "AuthorEntityResponse";
        data?: {
          __typename?: "AuthorEntity";
          attributes?: {
            __typename?: "Author";
            name?: string | null;
            bio?: string | null;
            email?: string | null;
            twitterHandle?: string | null;
            slug?: string | null;
            location?: string | null;
            createdAt?: any | null;
            updatedAt?: any | null;
            publishedAt?: any | null;
          } | null;
        } | null;
      } | null;
      featuredImage: {
        __typename?: "UploadFileEntityResponse";
        data?: {
          __typename?: "UploadFileEntity";
          attributes?: {
            __typename?: "UploadFile";
            formats?: any | null;
            alternativeText?: string | null;
          } | null;
        } | null;
      };
    } | null;
  } | null;
};

export type PageDataQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PageDataQuery = {
  __typename?: "Query";
  pages?: {
    __typename?: "PageEntityResponseCollection";
    data: Array<{
      __typename?: "PageEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Page";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryPageEntityResponse";
          data?: {
            __typename?: "CategoryPageEntity";
            id?: string | null;
            attributes?: { __typename?: "CategoryPage"; name?: string | null; slug: string } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type PostsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  blogFilters?: InputMaybe<BlogPostFiltersInput>;
  caseStudyFilters?: InputMaybe<CaseStudyFiltersInput>;
  eventFilters?: InputMaybe<EventFiltersInput>;
}>;

export type PostsQuery = {
  __typename?: "Query";
  blogPosts?: {
    __typename?: "BlogPostEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostEntity";
      id?: string | null;
      attributes?: {
        __typename?: "BlogPost";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        };
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryBlogPostEntityResponse";
          data?: {
            __typename?: "CategoryBlogPostEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryBlogPost";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
  caseStudies?: {
    __typename?: "CaseStudyEntityResponseCollection";
    data: Array<{
      __typename?: "CaseStudyEntity";
      id?: string | null;
      attributes?: {
        __typename?: "CaseStudy";
        title?: string | null;
        excerpt?: string | null;
        slug: string;
        post?: string | null;
        publishDate?: any | null;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryCaseStudyEntityResponse";
          data?: {
            __typename?: "CategoryCaseStudyEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryCaseStudy";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
  events?: {
    __typename?: "EventEntityResponseCollection";
    data: Array<{
      __typename?: "EventEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Event";
        title?: string | null;
        excerpt?: string | null;
        slug: string;
        post?: string | null;
        publishDate?: any | null;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        category?: {
          __typename?: "CategoryEventEntityResponse";
          data?: {
            __typename?: "CategoryEventEntity";
            id?: string | null;
            attributes?: {
              __typename?: "CategoryEvent";
              name?: string | null;
              slug: string;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type KeyResourcesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
}>;

export type KeyResourcesQuery = {
  __typename?: "Query";
  blogPosts?: {
    __typename?: "BlogPostEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostEntity";
      id?: string | null;
      attributes?: {
        __typename?: "BlogPost";
        title: string;
        post: string;
        slug: string;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        };
        category?: {
          __typename?: "CategoryBlogPostEntityResponse";
          data?: {
            __typename?: "CategoryBlogPostEntity";
            id?: string | null;
            attributes?: { __typename?: "CategoryBlogPost"; name?: string | null } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type KeyResourcesByTagQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  tags: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
}>;

export type KeyResourcesByTagQuery = {
  __typename?: "Query";
  blogPosts?: {
    __typename?: "BlogPostEntityResponseCollection";
    data: Array<{
      __typename?: "BlogPostEntity";
      id?: string | null;
      attributes?: {
        __typename?: "BlogPost";
        title: string;
        post: string;
        slug: string;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        };
        category?: {
          __typename?: "CategoryBlogPostEntityResponse";
          data?: {
            __typename?: "CategoryBlogPostEntity";
            id?: string | null;
            attributes?: { __typename?: "CategoryBlogPost"; name?: string | null } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            id?: string | null;
            attributes?: { __typename?: "BlogPostTag"; uid: string; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type LegalDataQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LegalDataQuery = {
  __typename?: "Query";
  legalPages?: {
    __typename?: "LegalPageEntityResponseCollection";
    data: Array<{
      __typename?: "LegalPageEntity";
      id?: string | null;
      attributes?: {
        __typename?: "LegalPage";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        } | null;
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type LegalSlugsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
}>;

export type LegalSlugsQuery = {
  __typename?: "Query";
  legalPages?: {
    __typename?: "LegalPageEntityResponseCollection";
    data: Array<{
      __typename?: "LegalPageEntity";
      id?: string | null;
      attributes?: { __typename?: "LegalPage"; slug: string } | null;
    }>;
  } | null;
};

export type NewsAndEventsCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type NewsAndEventsCategoriesQuery = {
  __typename?: "Query";
  categoryNewsAndEvents?: {
    __typename?: "CategoryNewsAndEventEntityResponseCollection";
    data: Array<{
      __typename?: "CategoryNewsAndEventEntity";
      attributes?: {
        __typename?: "CategoryNewsAndEvent";
        name: string;
        slug: string;
        description?: string | null;
      } | null;
    }>;
  } | null;
};

export type NewsEventsDataQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["I18NLocaleCode"]["input"]>;
  includedLocales?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type NewsEventsDataQuery = {
  __typename?: "Query";
  newsAndEvents?: {
    __typename?: "NewsAndEventEntityResponseCollection";
    data: Array<{
      __typename?: "NewsAndEventEntity";
      id?: string | null;
      attributes?: {
        __typename?: "NewsAndEvent";
        title: string;
        excerpt: string;
        slug: string;
        post: string;
        publishDate: any;
        metaTitle?: string | null;
        metaDescription?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        featuredImage: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
          } | null;
        };
        author?: {
          __typename?: "AuthorEntityResponse";
          data?: {
            __typename?: "AuthorEntity";
            id?: string | null;
            attributes?: {
              __typename?: "Author";
              name?: string | null;
              bio?: string | null;
              image?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: { __typename?: "UploadFile"; formats?: any | null } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        tags?: {
          __typename?: "BlogPostTagRelationResponseCollection";
          data: Array<{
            __typename?: "BlogPostTagEntity";
            id?: string | null;
            attributes?: { __typename?: "BlogPostTag"; name: string; uid: string } | null;
          }>;
        } | null;
        category?: {
          __typename?: "CategoryNewsAndEventEntityResponse";
          data?: {
            __typename?: "CategoryNewsAndEventEntity";
            id?: string | null;
            attributes?: { __typename?: "CategoryNewsAndEvent"; name: string; slug: string } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type NewsEventsSlugsQueryVariables = Exact<{ [key: string]: never }>;

export type NewsEventsSlugsQuery = {
  __typename?: "Query";
  newsAndEvents?: {
    __typename?: "NewsAndEventEntityResponseCollection";
    data: Array<{
      __typename?: "NewsAndEventEntity";
      id?: string | null;
      attributes?: { __typename?: "NewsAndEvent"; slug: string; locale?: string | null } | null;
    }>;
  } | null;
};

export type GetResourcesHomepageQueryVariables = Exact<{
  locale: Scalars["I18NLocaleCode"]["input"];
  tag?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetResourcesHomepageQuery = {
  __typename?: "Query";
  getResourcesHomepage?: {
    __typename?: "ResourcesHomepage";
    featuredResources?: Array<{
      __typename?: "Resource";
      id?: string | null;
      title?: string | null;
      excerpt?: string | null;
      featuredImage?: string | null;
      altText?: string | null;
      tag?: string | null;
      slug?: string | null;
      contentType?: string | null;
      minutes?: string | null;
      featuredImageFormats?: {
        __typename?: "StrapiImageFormats";
        thumbnail?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xsmall?: { __typename?: "StrapiImageFormat"; url: string } | null;
        small?: { __typename?: "StrapiImageFormat"; url: string } | null;
        large?: { __typename?: "StrapiImageFormat"; url: string } | null;
        medium?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xxlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
      } | null;
    } | null> | null;
    blogs?: Array<{
      __typename?: "Resource";
      id?: string | null;
      title?: string | null;
      excerpt?: string | null;
      featuredImage?: string | null;
      altText?: string | null;
      tag?: string | null;
      slug?: string | null;
      contentType?: string | null;
      minutes?: string | null;
      featuredImageFormats?: {
        __typename?: "StrapiImageFormats";
        thumbnail?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xsmall?: { __typename?: "StrapiImageFormat"; url: string } | null;
        small?: { __typename?: "StrapiImageFormat"; url: string } | null;
        large?: { __typename?: "StrapiImageFormat"; url: string } | null;
        medium?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xxlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
      } | null;
    } | null> | null;
    caseStudies?: Array<{
      __typename?: "Resource";
      id?: string | null;
      title?: string | null;
      excerpt?: string | null;
      featuredImage?: string | null;
      altText?: string | null;
      tag?: string | null;
      slug?: string | null;
      contentType?: string | null;
      minutes?: string | null;
      featuredImageFormats?: {
        __typename?: "StrapiImageFormats";
        thumbnail?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xsmall?: { __typename?: "StrapiImageFormat"; url: string } | null;
        small?: { __typename?: "StrapiImageFormat"; url: string } | null;
        large?: { __typename?: "StrapiImageFormat"; url: string } | null;
        medium?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xxlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
      } | null;
    } | null> | null;
    newsEvents?: Array<{
      __typename?: "Resource";
      id?: string | null;
      title?: string | null;
      excerpt?: string | null;
      featuredImage?: string | null;
      altText?: string | null;
      tag?: string | null;
      slug?: string | null;
      contentType?: string | null;
      minutes?: string | null;
      featuredImageFormats?: {
        __typename?: "StrapiImageFormats";
        thumbnail?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xsmall?: { __typename?: "StrapiImageFormat"; url: string } | null;
        small?: { __typename?: "StrapiImageFormat"; url: string } | null;
        large?: { __typename?: "StrapiImageFormat"; url: string } | null;
        medium?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
        xxlarge?: { __typename?: "StrapiImageFormat"; url: string } | null;
      } | null;
    } | null> | null;
  } | null;
};

export const BlogDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BlogData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPosts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "eq" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "caption" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlogDataQuery, BlogDataQueryVariables>;
export const BlogSlugsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BlogSlugs" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPosts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1000" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "StringValue", value: "all", block: false },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "locale" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlogSlugsQuery, BlogSlugsQueryVariables>;
export const CaseStudyCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CaseStudyCategories" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categoryCaseStudies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "30" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseStudyCategoriesQuery, CaseStudyCategoriesQueryVariables>;
export const CaseStudyDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CaseStudyData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "caseStudies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "eq" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseStudyDataQuery, CaseStudyDataQueryVariables>;
export const CaseStudySlugsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CaseStudySlugs" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "caseStudies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1000" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "StringValue", value: "all", block: false },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "locale" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseStudySlugsQuery, CaseStudySlugsQueryVariables>;
export const AllBlogPostsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllBlogPosts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationArg" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "BlogPostFiltersInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPosts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "StringValue", value: "publishDate:desc", block: false },
                    { kind: "StringValue", value: "createdAt:desc", block: false },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "alternativeText" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "isFeatured" } },
                            { kind: "Field", name: { kind: "Name", value: "featuredOrder" } },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllBlogPostsQuery, AllBlogPostsQueryVariables>;
export const AllCaseStudiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllCaseStudies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationArg" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CaseStudyFiltersInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "caseStudies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "StringValue", value: "publishDate:desc", block: false },
                    { kind: "StringValue", value: "createdAt:desc", block: false },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "alternativeText" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "isFeatured" } },
                            { kind: "Field", name: { kind: "Name", value: "featuredOrder" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllCaseStudiesQuery, AllCaseStudiesQueryVariables>;
export const AllLegalPagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllLegalPages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationArg" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "legalPages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllLegalPagesQuery, AllLegalPagesQueryVariables>;
export const AllNewsEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllNewsEvents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationArg" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "NewsAndEventFiltersInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "newsAndEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "StringValue", value: "updatedAt:desc", block: false },
                    { kind: "StringValue", value: "publishDate:desc", block: false },
                    { kind: "StringValue", value: "createdAt:desc", block: false },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "alternativeText" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "isFeatured" } },
                            { kind: "Field", name: { kind: "Name", value: "featuredOrder" } },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllNewsEventsQuery, AllNewsEventsQueryVariables>;
export const AllPagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllPages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PageFiltersInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationArg" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllPagesQuery, AllPagesQueryVariables>;
export const GetBlogPostDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getBlogPost" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getBlogPost" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "post" } },
                      { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                      { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                      { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "uid" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "icon" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "data" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "attributes" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "name" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "alternativeText",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "caption",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "width" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "height" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "formats",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "hash" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "ext" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "mime" },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "createdAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "updatedAt" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "bio" } },
                                        { kind: "Field", name: { kind: "Name", value: "email" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "twitterHandle" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "location" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "createdAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "updatedAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "publishedAt" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "formats" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "alternativeText" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBlogPostQuery, GetBlogPostQueryVariables>;
export const GetBlogTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBlogTags" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPostTags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "uid" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "url" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "alternativeText" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBlogTagsQuery, GetBlogTagsQueryVariables>;
export const GetCaseStudyPostDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCaseStudyPost" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getCaseStudyPost" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "post" } },
                      { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                      { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                      { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "uid" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "icon" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "data" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "attributes" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "name" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "alternativeText",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "caption",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "width" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "height" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "formats",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "hash" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "ext" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "mime" },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "createdAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "updatedAt" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "bio" } },
                                        { kind: "Field", name: { kind: "Name", value: "email" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "twitterHandle" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "location" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "createdAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "updatedAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "publishedAt" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "formats" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "alternativeText" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCaseStudyPostQuery, GetCaseStudyPostQueryVariables>;
export const GetFeaturedResourcesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFeaturedResources" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tag" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFeaturedResources" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "tag" },
                value: { kind: "Variable", name: { kind: "Name", value: "tag" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                { kind: "Field", name: { kind: "Name", value: "featuredImage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "featuredImageFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "thumbnail" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "xsmall" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "small" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "large" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "xlarge" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "xxlarge" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "tag" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "contentType" } },
                { kind: "Field", name: { kind: "Name", value: "minutes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeaturedResourcesQuery, GetFeaturedResourcesQueryVariables>;
export const GetGenericPropsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getGenericProps" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "options" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "GenericPropOptions" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getGenericProps" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "options" },
                value: { kind: "Variable", name: { kind: "Name", value: "options" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "latestCaseStudy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "img" } },
                      { kind: "Field", name: { kind: "Name", value: "alt" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "readTime" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "keyResources" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "img" } },
                      { kind: "Field", name: { kind: "Name", value: "alt" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "readTime" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGenericPropsQuery, GetGenericPropsQueryVariables>;
export const LegalPageDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LegalPageData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "legalPages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "eq" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LegalPageDataQuery, LegalPageDataQueryVariables>;
export const GetNewsAndEventPostDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getNewsAndEventPost" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getNewsAndEventPost" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "post" } },
                      { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                      { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                      { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "uid" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "icon" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "data" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "attributes" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "name" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "alternativeText",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "caption",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "width" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "height" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "formats",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "hash" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "ext" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "mime" },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "createdAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "updatedAt" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "bio" } },
                                        { kind: "Field", name: { kind: "Name", value: "email" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "twitterHandle" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "location" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "createdAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "updatedAt" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "publishedAt" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "attributes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "formats" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "alternativeText" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNewsAndEventPostQuery, GetNewsAndEventPostQueryVariables>;
export const PageDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PageData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "eq" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageDataQuery, PageDataQueryVariables>;
export const PostsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "posts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationArg" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "blogFilters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "BlogPostFiltersInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "caseStudyFilters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CaseStudyFiltersInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "eventFilters" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "EventFiltersInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPosts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "blogFilters" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "caseStudies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "caseStudyFilters" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "events" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "eventFilters" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostsQuery, PostsQueryVariables>;
export const KeyResourcesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "KeyResources" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPosts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "StringValue", value: "publishDate:desc", block: false },
                    { kind: "StringValue", value: "createdAt:desc", block: false },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeyResourcesQuery, KeyResourcesQueryVariables>;
export const KeyResourcesByTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "KeyResourcesByTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "blogPosts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: {
                  kind: "ListValue",
                  values: [
                    { kind: "StringValue", value: "publishDate:desc", block: false },
                    { kind: "StringValue", value: "createdAt:desc", block: false },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tags" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "uid" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "in" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tags" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeyResourcesByTagQuery, KeyResourcesByTagQueryVariables>;
export const LegalDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LegalData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "legalPages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "eq" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LegalDataQuery, LegalDataQueryVariables>;
export const LegalSlugsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LegalSlugs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "legalPages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1000" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LegalSlugsQuery, LegalSlugsQueryVariables>;
export const NewsAndEventsCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NewsAndEventsCategories" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categoryNewsAndEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "30" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsAndEventsCategoriesQuery, NewsAndEventsCategoriesQueryVariables>;
export const NewsEventsDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NewsEventsData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "includedLocales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "newsAndEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "eq" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "locale" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "in" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "includedLocales" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "featuredImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "formats" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "post" } },
                            { kind: "Field", name: { kind: "Name", value: "publishDate" } },
                            { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "metaDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "bio" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "image" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "data" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "id" },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "attributes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "formats",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "name" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "attributes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "uid" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "publishedAt" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsEventsDataQuery, NewsEventsDataQueryVariables>;
export const NewsEventsSlugsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NewsEventsSlugs" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "newsAndEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "1000" },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "StringValue", value: "all", block: false },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            { kind: "Field", name: { kind: "Name", value: "locale" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsEventsSlugsQuery, NewsEventsSlugsQueryVariables>;
export const GetResourcesHomepageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetResourcesHomepage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "I18NLocaleCode" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tag" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getResourcesHomepage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locale" },
                value: { kind: "Variable", name: { kind: "Name", value: "locale" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "tag" },
                value: { kind: "Variable", name: { kind: "Name", value: "tag" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "featuredResources" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "featuredImage" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImageFormats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thumbnail" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xsmall" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xxlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "altText" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "minutes" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "blogs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "featuredImage" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImageFormats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thumbnail" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xsmall" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xxlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "altText" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "minutes" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "caseStudies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "featuredImage" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImageFormats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thumbnail" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xsmall" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xxlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "altText" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "minutes" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newsEvents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                      { kind: "Field", name: { kind: "Name", value: "featuredImage" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredImageFormats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thumbnail" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xsmall" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xxlarge" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "altText" } },
                      { kind: "Field", name: { kind: "Name", value: "tag" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "minutes" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResourcesHomepageQuery, GetResourcesHomepageQueryVariables>;
