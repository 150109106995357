import { BlogPostFiltersInput, PaginationArg } from "gql/types/graphql";

export const isValidPagination = (pagination: PaginationArg): boolean =>
  !pagination ||
  [pagination.page, pagination.limit, pagination.pageSize].every(
    (value) => value === undefined || Number.isInteger(value),
  );

export const isValidFilters = (filters: BlogPostFiltersInput): boolean =>
  typeof filters !== "string";
