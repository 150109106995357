import { gql } from "urql";

export const blogSlugs = gql(`
  query BlogSlugs {
    blogPosts(pagination: { pageSize: 1000 }, locale: "all") {
      data {
        id
        attributes {
          slug
          locale
        }
      }
    }
  }
`);
