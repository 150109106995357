import { gql } from "urql";

export const legalSlugs = gql(`
  query LegalSlugs($locale: I18NLocaleCode) {
    legalPages(pagination: { pageSize: 1000 }, locale: $locale) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`);
