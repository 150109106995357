import styled from "styled-components";
import { colour } from "@styles";

export const LoadingSpinner = styled.div`
  border: 1rem solid transparent;
  border-top: 1rem solid ${colour.P600};
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  animation: spin 2s linear infinite;
  z-index: 5;
  top: 0;
  left: 0;
  pointer-events: none;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
