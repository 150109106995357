import { FC, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { space, device, colour } from "@styles";
import { file } from "@utils";
import { LoadingSpinner } from "../../components/molecules/LoadingSpinner";
import { mobile } from "components/templates/Breakpoints";

interface Props {
  vimeoId: string;
  isOpen?: boolean;
  isShowingButton?: boolean;
  onClose?: VoidFunction;
}

export const Vimeo: FC<React.PropsWithChildren<Props>> = ({
  vimeoId,
  isOpen: open,
  isShowingButton,
  onClose,
}) => {
  if (!vimeoId) {
    return null;
  }

  const src = `https://player.vimeo.com/video/${vimeoId}?h=9a68828d19&color=E30D76&title=0&byline=0&portrait=0&autoplay=0&loop=0&autopause=0`;

  const [isOpen, setIsOpen] = useState(open);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const openVideo = () => {
    setShowSpinner(true);
    setIsOpen(true);
    setIsLoading(true);
  };

  const closeVideo = () => {
    setShowSpinner(false);
    setIsLoading(true);
    setTimeout(() => setIsOpen(false), 200);
    if (onClose) {
      onClose();
    }
  };

  const hideSpinner = () => setIsLoading(false);

  return (
    <>
      {isShowingButton && (
        <PlayButton
          onClick={openVideo}
          src={file("/img/homepage-hero/PlayButton.svg")}
          tabIndex={0}
        />
      )}
      <Background onClick={closeVideo} isOpen={isOpen}>
        <ExitButton onClick={closeVideo}>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.1 1.1c.6-.6 1.5-.6 2.1 0L14 11.9 24.8 1.1a1.5 1.5 0 1 1 2.1 2.1L16.1 14l10.8 10.8a1.5 1.5 0 1 1-2.1 2.1L14 16.1 3.2 26.9a1.5 1.5 0 1 1-2.1-2.1L11.9 14 1.1 3.2c-.6-.6-.6-1.5 0-2.1Z"
              fill="#fff"
            />
          </svg>
        </ExitButton>
        <Wrapper>
          <SpinnerWrapper>{isLoading && showSpinner ? <LoadingSpinner /> : null}</SpinnerWrapper>
          <VideoWrapper isLoading={isLoading}>
            <Video
              onLoad={hideSpinner}
              src={src}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></Video>
          </VideoWrapper>
        </Wrapper>
      </Background>
    </>
  );
};

const Background = styled.div<{ isOpen?: boolean }>`
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  transform: scale(1);
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
`;

const Wrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: ${space.S2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background: none;
  cursor: pointer;
  position: relative;
  z-index: 99;

  @media ${device.tablet} {
    padding: ${space.S4};
  }
`;

const ExitButton = styled.button`
  position: absolute;
  padding: ${space.S2};
  background: none;
  border: none;
  fill: ${colour.white};
  cursor: pointer;
  top: 5rem;
  right: 5rem;

  @media only screen and (max-width: 1150px) {
    top: 0.5rem;
    right: 0.5rem;
    transform: scale(0.8);
  }

  ${mobile} {
    transform: scale(0.66);
    transform-origin: top right;
  }
`;

const Video = styled.iframe`
  max-width: 100%;
  width: 60rem;
  height: 33.75rem;
  position: relative;
  top: 0;
  z-index: 99;
`;

const PlayButton = styled.img`
  cursor: pointer;
  transition: 200ms ease-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const SpinnerWrapper = styled.div`
  max-width: 100%;
  width: 60rem;
  height: 33.75rem;
  top: 3.625rem;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  pointer-events: none;
`;

const VideoWrapper = styled.div<{ isLoading?: boolean }>`
  transition: ${(p) => (p.isLoading ? "250ms" : "400ms")} ease-out;
  opacity: ${(p) => (p.isLoading ? 0 : 1)};
  transform: translateY(${(p) => (p.isLoading ? "6.25rem" : "0rem")});
`;
