import { gql } from "urql";

export const caseStudyCategories = gql(`
  query CaseStudyCategories {
    categoryCaseStudies(pagination: { pageSize: 30 }) {
      data {
        attributes {
          name
          slug
          description
        }
      }
    }
  }
`);
