import { gql } from "urql";

export const GET_FEATURED_RESOURCES_QUERY = gql(`
  query GetFeaturedResources($locale: I18NLocaleCode!, $tag: String) {
    getFeaturedResources(locale: $locale, tag: $tag) {
      id
      title
      excerpt
      featuredImage
      featuredImageFormats {
        thumbnail {
          url
        }
        xsmall {
          url
        }
        small {
          url
        }
        large {
          url
        }
        medium {
          url
        }
        xlarge {
          url
        }
        xxlarge {
          url
        }
      }      
      tag
      slug
      contentType
      minutes
    }
  }
`);
