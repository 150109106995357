import { gql } from "urql";

export const caseStudyData = gql(`
  query CaseStudyData($slug: String, $locale: I18NLocaleCode) {
    caseStudies(filters: { slug: { eq: $slug } }, pagination: { pageSize: 1 }, locale: $locale) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          tags {
            data {
              id 
              attributes {
                uid
                name
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`);
