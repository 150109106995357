import { gql } from "urql";

const GET_GENERIC_PROPS_QUERY = gql(`
  query getGenericProps($locale: I18NLocaleCode!, $options: GenericPropOptions!) {
    getGenericProps(locale: $locale, options: $options){
      latestCaseStudy {
        tag
        img
        alt
        title
        readTime
        slug
      }
      keyResources {
        tag
        img
        alt
        title
        readTime
        slug
      }
    }
  }
`);

export default GET_GENERIC_PROPS_QUERY;
