import Bugsnag from "@bugsnag/js";
import { Logger } from "services/logger";

export const teamTailorErrorResponse = (method: string, response: Response) => {
  return `TeamTailorService.${method} failed with status code ${response.status}. Message: ${response.statusText}.`;
};

export const handleTeamTailorErrors = (response: Response, method: string) => {
  if (response.status === 403 || response.status === 429) {
    // Handle 403 and 429 errors specifically, but do not notify Bugsnag
    Logger.error(teamTailorErrorResponse(method, response));
    return null;
  }

  if (response.status !== 200) {
    Logger.error(teamTailorErrorResponse(method, response));
    Bugsnag.notify(new Error(teamTailorErrorResponse(method, response)));
    return null;
  }
  return response;
};
