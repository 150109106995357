import { gql } from "urql";

export const newsEventsSlugs = gql(`
  query NewsEventsSlugs {
    newsAndEvents(pagination: { pageSize: 1000 }, locale: "all") {
      data {
        id
        attributes {
          slug
          locale
        }
      }
    }
  }
`);
