import { gql } from "urql";

const GET_ALL_LEGAL_PAGES_QUERY = gql(`
  query AllLegalPages($pagination: PaginationArg) {
    legalPages(pagination: $pagination) {
      data {
        id
        attributes {
          slug
          title
        }
      }
    }
  }
`);

export default GET_ALL_LEGAL_PAGES_QUERY;
