import { gql } from "urql";

const GET_BLOG_TAGS_QUERY = gql(`
query GetBlogTags {
  blogPostTags {
    data {
      id
      attributes {
        name
        uid
        description
        icon {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
      }
    }
  }
}`);

export default GET_BLOG_TAGS_QUERY;
