import { gql } from "urql";

const GET_ALL_PAGES_QUERY = gql(`
  query AllPages($filters: PageFiltersInput, $pagination: PaginationArg) {
    pages(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          slug
          title
        }
      }
    }
  }
`);

export default GET_ALL_PAGES_QUERY;
