import { gql } from "urql";

export const caseStudySlugs = gql(`
  query CaseStudySlugs {
    caseStudies(pagination: { pageSize: 1000 }, locale: "all") {
      data {
        id
        attributes {
          slug
          locale
        }
      }
    }
  }
`);
