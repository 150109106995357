import { gql } from "urql";

const GET_LEGAL_PAGE_DATA = gql(`
  query LegalPageData($locale: I18NLocaleCode, $slug: String) {
    legalPages(filters: { slug: { eq: $slug } }, pagination: { pageSize: 1 }, locale: $locale) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`);

export default GET_LEGAL_PAGE_DATA;
