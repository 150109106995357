import { gql } from "urql";

export const keyResourcesByTag = gql(`
  query KeyResourcesByTag($locale: I18NLocaleCode, $tags: [String]!) {
    blogPosts(
      pagination: { pageSize: 3, page: 1 }
      sort: ["publishDate:desc", "createdAt:desc"]
      filters: {
        tags: { uid: { in: $tags } }
      }
      locale: $locale
    ) {
      data {
        id
        attributes {
          title
          post
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          tags {
            data {
              id
              attributes {
                uid
                name
              }
            }
          }
        }
      }
    }
  }
`);
