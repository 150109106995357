import { YuLifeLocale } from "hooks";

export const getStrapiLocale = (locale: YuLifeLocale) => {
  switch (locale) {
    case "us":
      return "en-US";
    case "za":
      return "en-ZA";
    case "jp":
      return "ja-JP";
    case "uk":
    default:
      return "en";
  }
};
